import { useEffect, useState } from 'react';
import {generateLoginUrl, getAgentChatConfigFromStorage} from "../configs/configHelper";
import {AppState} from "../utils/constant";

export const useAgentChatConfiguration = (clientId, loginUrl, callbackAppState) => {
	const [agentChatConfiguration, setAgentChatConfiguration] = useState({});

	useEffect(() => {
		callbackAppState(AppState.RELOAD);
		getAgentChatConfigFromStorage(clientId)
			.then(result => {
				let config = result || {};
				config.xApiUrl = process.env.REACT_APP_AMAZON_API_URL;
				config.xApiKey = process.env.REACT_APP_AMAZON_API_KEY;
				if (!loginUrl) {
					config.loginUrl = generateLoginUrl(
						config.xApiUrl,
						config.instanceId
					);
				} else {
					config.loginUrl = insertInstanceIdToUrl(loginUrl, config.instanceId);
				}
				setAgentChatConfiguration(config);
			})
			.catch(() => {
				callbackAppState(AppState.ERROR);
			});
	}, [clientId]);

	const insertInstanceIdToUrl = (url, instanceId) => {
		const parsedUrl = new URL(url);
		const redirectUri = parsedUrl.searchParams.get('redirect_uri');
		if (redirectUri) {
			const redirectUrl = new URL(redirectUri);
			redirectUrl.searchParams.set('instance_id', instanceId);
			parsedUrl.searchParams.set('redirect_uri', redirectUrl.toString());
		} else if (parsedUrl.searchParams.get('instance_id')) {
			parsedUrl.searchParams.set('instance_id', instanceId);
		} else {
			parsedUrl.searchParams.append('instance_id', instanceId);
		}

		return parsedUrl.toString();
	};

	return agentChatConfiguration;
}