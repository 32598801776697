import React from 'react';
import Alert from 'react-bootstrap/Alert';

import style from './CustomAlert.module.css';

function CustomAlert({alert, setAlert, variant = "success", children}) {
	return (
		alert &&
		<section className={style.customAlertContainer}>
			<Alert
				dismissible
				variant={variant}
				onClose={() => setAlert(null)}>
				<Alert.Heading>{alert.title}</Alert.Heading>
				<p>{alert.text}</p>
				{children &&
					<div className={style.customAlertChildrenContainer}>
						{children}
					</div>}
			</Alert>
		</section>
	);
}

export default CustomAlert;