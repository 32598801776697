import React from "react";
import {Oval as LoaderOval} from "react-loader-spinner";

export const ReferralInfoMessage = (props) =>
{
    const {data, messageTime} = props;
    const {token, tokenData, mrn, department, ref, queue} = data;

    return <div className={"row message-left"}>
        <div className="col-md-9">
            <p>
                {
                    !token ?
                        <>
                            Patient MRN: {mrn}<br/>
                            Appointment department: {department}<br/>
                            Patient referral number: {ref}<br/>
                        </>
                        :
                        (
                            tokenData ?
                                <>
                                    Patient MRN: {tokenData.patientMrn}<br/>
                                    Appointment department: {queue}<br/>
                                    Patient referral full name: {tokenData.patientFullName}<br/>
                                    Patient referral team: {tokenData.referralTeam}<br/>
                                    Patient referral extended id: {tokenData.referralExtId}<br/>
                                </>
                                :
                                <>
                                    Referral info loading ...
                                    <LoaderOval
                                        color="#d7d7d7"
                                        height={36}
                                        width={36}
                                        timeout={0}
                                    />
                                </>
                        )
                }
            </p>
            <span className={"message-time "}>
                {messageTime}
            </span>
        </div>
        <div className="col-md-3">
        </div>
    </div>;
}
