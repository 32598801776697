import {sendErrorLog} from "./logger";

const storagePrefix = 'Chatbot_';

function storageGetItem(key) {
	return sessionStorage.getItem(storagePrefix + key);
}

function storageSetItem(key, value) {
	return sessionStorage.setItem(storagePrefix + key, value);
}

export const loadUnreadMessages = () => {
	let result = {};
	try {
		result = JSON.parse(storageGetItem('unreadMessages'));
	} catch (e) {
		sendErrorLog('Can\'t load unread messages information', {message: e.message});
	}
	return (result ? result : {});
};

export const saveUnreadMessages = (unreadMessages) => {
	storageSetItem('unreadMessages', JSON.stringify(unreadMessages));
};

export const loadTranscripts = () => {
	let result = {};
	try {
		result = JSON.parse(storageGetItem('transcripts'));
	} catch (e) {
		sendErrorLog('Can\'t load saved transcripts', {message: e.message});
	}
	return (result ? result : {});
};

export const saveTranscripts = (transcripts) => {
	storageSetItem('transcripts', JSON.stringify(transcripts));
};

export const loadContactsAdditionalData = () => {
	let result = {};
	try {
		result = JSON.parse(storageGetItem('contactsAD'));
	} catch (e) {
		sendErrorLog('Can\'t load saved contacts additional data', {message: e.message});
	}
	return (result ? result : {});
};

export const saveContactsAdditionalData = (data) => {
	storageSetItem('contactsAD', JSON.stringify(data));
};

export const saveContactsErrorStatus = (data) => {
	storageSetItem('contactsErrStatus', JSON.stringify(data));
};

export const loadContactsErrorStatus = () => {
	let result = {};
	try {
		result = JSON.parse(storageGetItem('contactsErrStatus'));
	} catch (e) {
		sendErrorLog('Can\'t load saved contact status', {message: e.message});
	}
	return (result ? result : {});
};

export const saveSelectedCategory = (data) => {
	storageSetItem('selectedCategory', JSON.stringify(data));
};

export const loadSelectedCategory = () => {
	let result = {};
	try {
		result = JSON.parse(storageGetItem('selectedCategory'));
	} catch (e) {
		sendErrorLog('Can\'t load saved selected category', {message: e.message});
	}
	return (result ? result : {});
};

export const loadUserData = () => {
	let result = {};
	try {
		result = JSON.parse(sessionStorage.getItem(storagePrefix + 'UD'));
	} catch (e) {
		sendErrorLog('Can\'t load saved contacts additional data', {message: e.message});
	}
	return (result ? result : {});
};

export const saveUserData = (data) => {
	sessionStorage.setItem(storagePrefix + 'UD', JSON.stringify(data));
};