import "amazon-connect-streams";
import "amazon-connect-chatjs";
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Buffer} from 'buffer';


import {
    AlertErrors,
    AppState,
    ContactState,
    InfoMessages, QUEUE_NAME_RN, QUEUE_TYPE_RN,
    TIMEOUT_AGENT_CHAT_PAGE_RELOAD_EVENT,
    TIMEOUT_AGENT_CHAT_RELOAD_EVENT,
    TIMEOUT_TYPING_EVENT,
    QUEUE_NAME_BILLING,
    QUEUE_NAME_BILLING_ES
} from "../utils/constant";
import Messages from "./Messages/Messages";
import Contacts from "./Contacts/Contacts";
import Header from "./Header";
import Templates from './Templates/Templates';
import CustomAlert from './common/CustomAlert/CustomAlert';
import DropDownChatCategories from './DropDownChatCategories/DropDownChatCategories';
import CustomModal from './common/CustomModal/CustomModal';
import TextareaAutosize from 'react-textarea-autosize';
import {Oval as LoaderOval} from "react-loader-spinner";

import {useBeforeunload} from 'react-beforeunload';
import {checkEmptyInputText, i18n, isIn, removeFromArray, removeFromObject, scrollToBottom} from '../utils/helpers';
import {initLogger, sendErrorLog, sendInfoLog} from "../utils/logger";
import {
    loadContactsAdditionalData,
    loadContactsErrorStatus, loadSelectedCategory,
    loadTranscripts,
    loadUnreadMessages, loadUserData, saveContactsAdditionalData,
    saveContactsErrorStatus,
    saveTranscripts,
    saveUnreadMessages, saveUserData
} from "../utils/storage";

import {useAgentConfiguration} from '../hooks/useAgentConfiguration';
import {useChatCategories} from '../hooks/useChatCategories';
import {useCommonAnswers} from '../hooks/useCommonAnswers';

import '../styles/main.scss'
import '@fortawesome/fontawesome-free/css/all.css';
import {
    connect,
    createAgentChatSession,
    getContactData, getContactQueueName,
    initConnect,
    installConnectFixes,
    setChatSessionGlobalConfig,
    subscribeToConnectLogs
} from "../services/Amazon/Connect";
import api from "../services/api";
import LoadTranscripts from "./LoadTranscripts";
import {useAgentChatConfiguration} from "../hooks/useAgentChatConfiguration";
import useStatusStore from '../stores/Status';
import { useShallow } from 'zustand/react/shallow';
import useExceptionStore from "../stores/Exception";

var messageSound, contactSound;
var lastMessageId;
var windowFocused = true;
//var isChatLoaded = false; // ToDo:Rework
var isContactLoaded = [];
var isContactProcessing = {};
var contactTransferred = [];
var chatSession = {};

const playSound = (sound, looped, ignoreIfPlaying) => {
    if (sound) {
        try {
            if (ignoreIfPlaying && !sound.paused) {
                return;
            }
            sound.currentTime = 0;
            sound.muted = false;
            sound.loop = looped || false;
            sound.play();
        } catch (e) {
            sendErrorLog('Try play sound', {message: e.message});
        }
    }
}

const stopSound = (sound) => {
    try {
        if (sound && !sound.paused) {
            sound.pause();
            sound.currentTime = 0;
        }
    } catch (e) {}
};

const initNotifications = () => {
    try {
        const nm = connect.core.getNotificationManager();
        if (nm) {
            nm.requestPermission();
        }
    } catch (e) {
        sendErrorLog('Can\'t init notifications', {message: e.message});
    }
};

const showNotification = (title, body) => {
    try {
        const nm = connect.core.getNotificationManager();
        if (nm) {
            if (nm.permission.toLowerCase() === 'default') {
                nm.requestPermission();
            }
            nm.show(title, {
                icon: '',
                body: body,
                clicked: () => {
                    window.focus();
                }
            });
            nm.queue = [];
        }
    } catch (e) {
        sendErrorLog('Can\'t show notification', {message: e.message});
    }
};

const logInfoMsg = (msg) => {
    connect.getLog().info(msg);
};

const logInfoEvent = (eventMsg) => {
    connect.getLog().info(eventMsg);
};

const AgentChat = forwardRef((props, ref) => {
    const { changeByAgentStatus, isOnline } = useStatusStore(useShallow((state) => ({ ...state })));
    const { alertError, setAlertError, infoMessage, setInfoMessage } = useExceptionStore(useShallow((state) => ({ ...state })));
    const [appState, setAppState] = useState(AppState.NORMAL);
    const [contactsState, setContactsState] = useState({});
    const [isChatLoaded, setChatLoaded] = useState(false);
    const [messages, setMessages] = useState(loadTranscripts);
    //const [chatSession, setChatSession] = useState({});
    const [agentMessages, setAgentMessages] = useState({});
    const [currentAgentMessage, setCurrentAgentMessage] = useState('');
    const [incoming, setIncoming] = useState(false);
    const [incomingContact, setIncomingContact] = useState(null);
    const [currentContact, setCurrentContact] = useState(null);
    const [contacts, setContacts] = useState({});
    const [contactsAdditionalData, setContactsAdditionalData] = useState(loadContactsAdditionalData);
    const [unreadMessages, setUnreadMessages] = useState(loadUnreadMessages);
    const [agentName, setAgentName] = useState(null);
    const [isTyping, setIsTyping] = useState({});
    const [endpoints, setEndpoints] = useState(null);
    const [selectedChatCategory, setSelectedChatCategory] = useState({});
    const [renderDropDownCategories, setRenderDropDownCategories] = useState(false)
    const [prescription, setPrescription] = useState({});
    const [immunization, setImmunization] = useState({});
    const [nurseAdvice, setNurseAdvice] = useState({});
    const [glp, setGlp] = useState({});
    // const [windowFocused, setWindowFocused] = useState(true);
    const [currentQueueName, setCurrentQueueName] = useState(null);
    const [loadMoreTranscripts, setLoadMoreTranscripts] = useState({});
    const [contactsErrorStatus, setContactsErrorStatus] = useState(loadContactsErrorStatus);
    const [canOffline, setCanOffline] = useState(true);
    const [activeContacts, setActiveContacts] = useState(0);
    const [templateModalData, setTemplateModalData] = useState({show: false});
    const [templateUsageStatistic, setTemplateUsageStatistic] = useState({});
    const [userData, setUserData] = useState(loadUserData);
    const [isEnabledMessaging, setIsEnabledMessaging] = useState(false);
    const [hideDropdown, setHideDropdown] = useState(false);
    const [showInputError, setShowInputError] = useState({});

    const textArea = useRef(null);
    const containerRef = useRef(null);

    // hooks
    const agentChatConfiguration = useAgentChatConfiguration(props.clientId ? props.clientId : props.orgId, props.loginUrl, setAppState);
    const agentConfiguration = useAgentConfiguration();
    const enableDropDownCategories = useChatCategories(agentChatConfiguration.chatCategories, contacts, currentContact);
    const isTemplatesAvailable = useCommonAnswers(agentChatConfiguration.commonAnswers, currentQueueName);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    scrollToBottom(true)
                }
            },
            {root: null, threshold: 0.1,}
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    useBeforeunload(event => {
        if (isOnline() && !alertError) {
            return "You are still Online and able to receive new messages. Please update your status before closing.";
        }
    });

    useImperativeHandle(ref, () => ({
        doReloadChat() {
            reloadChat();
        },
        doTerminate() {
            terminateChat();
        },
        doShowDebugInfo() {
            console.log('^Current Contact', currentContact);
            console.log('^Sessions', chatSession);
            console.log('^Contacts', contacts);
            console.log('^Contacts additional data', contactsAdditionalData);
            console.log('^Contacts user data', userData);
            console.log('^Contacts transferred', contactTransferred);
            console.log('^Contacts loaded', isContactLoaded);
            console.log('^Contacts status', contactsErrorStatus);
            console.log('^Contacts selected chat category', selectedChatCategory);
            console.log('^Messages', messages);
            console.log('^Agent Messages', agentMessages);
            console.log('^Load More Transcripts', loadMoreTranscripts);
            //---
            console.log('^AgentConfig', window.myCPP.agent.getConfiguration());
        },
        doShowError() {
            showAlert(AlertErrors.COMMON);
        },
        doShowNetworkError() {
            showAlert(AlertErrors.NETWORK);
        },
        doLogoutAgent() {
            return logoutAgent()
        }
    }));

    const logoutAgent = () => {
        const isCanLogout = canAgentChangeStatus()

        if (isCanLogout) {
            goOffline()
            return {isCanLogout, message: { title: '', text: '', showCloseButton: false }}
        }

        return { isCanLogout, message: InfoMessages.CANT_GO_OFFLINE, }
    }

    useEffect(() => {
        const onWindowFocus = () => {
            windowFocused = true;
        };
        const onWindowBlur = () => {
            windowFocused = false;
        };
        window.addEventListener("focus", onWindowFocus);
        window.addEventListener("blur", onWindowBlur);
        return () => {
            window.removeEventListener("focus", onWindowFocus);
            window.removeEventListener("blur", onWindowBlur);
        };
    }, []);

    useEffect(() => {
        try {
            setAgentName(null);
            terminateChat();
        } catch (e) {}
        initAgentChat();
    }, [agentChatConfiguration]);

    useEffect(() => {
        if (windowFocused) {
            clearUnreadMessages(currentContact);
        }
        saveUnreadMessages(unreadMessages);
    }, [unreadMessages]);

    useEffect(() => {
        console.log("inside contacts and current contacts useEffect");
        // Update current queue name
        try {
            setCurrentQueueName(
                contacts[currentContact]?.connection?.getQueue().name || null
            );
        } catch (e) {
            setCurrentQueueName(null);
        }
        // Restore transcript for ended chat
        try {
            if (
                currentContact &&
                contacts[currentContact]?.state?.type === "ended" &&
                !messages[currentContact]
            ) {
                loadTranscript(currentContact);
            }
        } catch (e) {}
        try {
            let ac = 0;
            Object.keys(contacts).map((keyName) => {
                if (contacts[keyName].connected) {
                    ac++;
                }
                setContactsAdditionalData((prevState) => ({
                    ...prevState,
                    [keyName]: contacts[keyName].additionalData,
                }));
            });
            setActiveContacts(ac);
        } catch (e) {}
        try {
            if (Object.keys(contactsErrorStatus).length) {
                changeInfoMessage(InfoMessages.CHAT_MISSED_OR_REJECTED);
            }
        } catch (e) {}
        const isContactConnected = contacts[currentContact] && contacts[currentContact]?.state?.type === 'connected';
        setHideDropdown(!isContactConnected && isEnabledMessaging);
        setIsEnabledMessaging(isContactConnected);
        if (!isContactConnected && textArea) {
            updateAgentMessage('');
            textArea.current.blur();
        }
    }, [contacts, currentContact]);

    useEffect(() => {
        saveTranscripts(messages);
    }, [messages]);

    useEffect(() => {
        saveContactsAdditionalData(contactsAdditionalData);
    }, [contactsAdditionalData]);

    useEffect(() => {
        if (!activeContacts) {
            setCanOffline(true)
        }
    }, [activeContacts]);

    useEffect(() => {
        if (canOffline) {
            setInfoMessage(null)
        }
    },[canOffline]);

    useEffect(() => {
        if (agentName) {
            setAlertError(null);
            setAppState(AppState.NORMAL);
        }
    }, [agentName]);

    const showAlert = (alert) => {
        if (alert === AlertErrors.NETWORK) {
            setTimeout(() => {
                changeInfoMessage(InfoMessages.AFTER_REFRESH_ON_NETWORK_ERROR);
                reloadChat();
            }, TIMEOUT_AGENT_CHAT_RELOAD_EVENT);
        } else if (alert === AlertErrors.COMMON) {
            setTimeout(() => {
                window.location.reload(); // reload the page
            }, TIMEOUT_AGENT_CHAT_PAGE_RELOAD_EVENT);
        }

        if (!alertError) {
            setAlertError(alert);
        }
    }

    useEffect(() => {
        setCurrentAgentMessage(agentMessages[currentContact]?.input || '');
        scrollToBottom(false);
    }, [currentContact]);

    useEffect(() => {
        if (isEnabledMessaging && document.activeElement !== textArea.current) {
            textArea.current.focus();
        }
    }, [currentAgentMessage]);

    useEffect(() => {
        saveContactsErrorStatus(contactsErrorStatus);
        try {
            if (!Object.keys(contactsErrorStatus).length
                && infoMessage === InfoMessages.CHAT_MISSED_OR_REJECTED
            ) {
                setInfoMessage(null);
            }
        } catch (e) {
            setInfoMessage(null);
        }
    },[contactsErrorStatus]);

    useEffect(() => {
        if (incoming) {
            playSound(contactSound, true, true);
        } else {
            stopSound(contactSound);
        }
    }, [incoming]);

    const clearUnreadMessages = (contactId) => {
        if (unreadMessages[currentContact] !== null) {
            setUnreadMessages(prevState => ({
                ...prevState,
                [contactId]: null
            }));
        }
    };

    const initAgentChat = () => {
        initLogger();
        installConnectFixes();

        if (!agentChatConfiguration.ccpUrl || !agentChatConfiguration.loginUrl) {
            return;
        }

        setAppState(AppState.NORMAL);

        window.myCPP = window.myCPP || {};

        const containerDiv = document.getElementById('agent-ccp-container');
        window.myCPP.containerDiv = containerDiv;

        initConnect({
            containerDiv: containerDiv,
            ccpUrl: agentChatConfiguration.ccpUrl,
            loginUrl: agentChatConfiguration.loginUrl,
        });

        if (!messageSound) {
            messageSound = new Audio('https://static.odeza.com/assets/media/sms-alert-1-daniel_simon.mp3');
        }
        if (!contactSound) {
            // ringtone
            contactSound = new Audio('https://static.odeza.com/assets/media/sms-alert.mp3');
        }

        connect.core.onIframeRetriesExhausted(() => {
            showAlert(AlertErrors.NETWORK);
            sendErrorLog('Iframe retries exhausted');
        });
        connect.core.onAuthFail(() => {
            showAlert(AlertErrors.NETWORK);
            sendErrorLog('Auth failed');
        });
        connect.core.onAccessDenied(() => {
            showAlert(AlertErrors.COMMON);
            sendErrorLog('Access denied');
        });

        connect.core.onInitialized(() => {
            subscribeToConnectLogs((alert) => {
                showAlert(alert);
            });
        });

        connect.contact(subscribeToContactEvents);
        connect.agent(subscribeToAgentEvents);
        console.log(contacts[currentContact])
        initNotifications();
        scrollToBottom();
    }

    const reloadChat = () => {
        if (!agentChatConfiguration.ccpUrl || !agentChatConfiguration.loginUrl) {
            window.location.reload();
            return;
        }
        setAgentName(null);
        setAppState(AppState.RELOAD);
        try {
            const cppIframe = window.myCPP.containerDiv.getElementsByTagName('iframe')[0];
            if (cppIframe) {
                const saveSrc = cppIframe.src;
                cppIframe.src = '';
                setTimeout(() => {
                    cppIframe.src = saveSrc
                }, 250);
            }
        } catch (e) {
            sendErrorLog('Reload chat failed', {message: e.message});
        }
    };

    const loadTranscript = (contactId, getPrevious) => {
        if (!contactId) {
            return;
        }
        setContactsState(prevState => ({
            ...prevState,
            [contactId]: ContactState.LOADING
        }));
        let clientApiKey = '';
        try {
            clientApiKey = contacts[contactId].getAttributes().apiKey;
        } catch (e) {
            sendErrorLog('Can\'t get client API key', {message: e.message});
        }
        api.getContactTranscript({
            xApiUrl: agentChatConfiguration.xApiUrl,
            xApiKey: agentChatConfiguration.xApiKey,
            clientApiKey,
            contactId,
            getPrevious
        })
            .then((data) => {
                if (data?.haveMore) {
                    setLoadMoreTranscripts(prevState => ({
                        ...prevState,
                        [contactId]: {
                            load: true,
                            failed: prevState.failed
                        }
                    }));
                }
                if (getPrevious) {
                    if (data?.transcript) {
                        setMessages(prevState => ({
                            ...prevState,
                            [contactId]: [
                                ...data.transcript,
                                ...prevState[contactId]
                            ]
                        }));
                    }
                    setLoadMoreTranscripts(prevState => ({
                        ...prevState,
                        [contactId]: {
                            load: false,
                            failed: prevState.failed
                        }
                    }));
                } else {
                    setMessages(prevState => ({
                        ...prevState,
                        [contactId]: data?.transcript || null
                    }));
                }
            })
            .catch(error => {
                sendErrorLog('Load transcript failed', {error: error});
                if (getPrevious) {
                    setLoadMoreTranscripts(prevState => ({
                        ...prevState,
                        [contactId]: {
                            load: true,
                            failed: true
                        }
                    }));
                }
            })
            .finally(() => {
                setContactsState(prevState => ({
                    ...prevState,
                    [contactId]: ContactState.NORMAL
                }));
            });
    }

    const subscribeToContactEvents = (contact) => {
        window.myCPP.contact = contact;
        logInfoMsg("Subscribing to events for contact");

        contact.onConnecting(handleContactConnecting);
        contact.onConnected(handleContactConnected);
        contact.onMissed(handleContactMissed);
        contact.onIncoming(handleContactIncoming);
        contact.onAccepted(handleContactAccepted);
        contact.onEnded(handleContactEnded);
        contact.onDestroy(handleContactDestroy);
    };

    const disconnectChat = (contactId) => {
        const targetContactId = contactId || currentContact;
        let targetContact = contacts[targetContactId];
        if (targetContact) {
            if (targetContact.state?.type === 'ended') {
                disconnectContact(targetContactId);
            } else {
                endChat(targetContactId);
            }
            targetContact.additionalData.chatEndedByAgent = true;
            setContacts(prevState => ({
                ...prevState,
                [targetContactId]: targetContact
            }));
            setShowInputError({[targetContactId]:false})
        }
    };

    const handleContactConnecting = (contact) => {
        setIncoming(true);
        const contactId = contact.contactId;
        const contactData = getContactData(contact);
        let incomingContactData = contactData?.attributes;
        console.log("^incomingContactData", incomingContactData);
        try {
            incomingContactData.queueName = getContactQueueName(contact);
            incomingContactData.contactId = contactId;
        } catch (e) {}
        setIncomingContact(incomingContactData);

        let additionalData = {};
        try {
            additionalData = JSON.parse(incomingContactData.additionalData.value) || {};
        } catch (e) {}
        if (additionalData.token) {
            // save token
            setUserData(prevState => {
                let uData = prevState[contactId] || {};
                uData.token = additionalData.token;
                return {
                    ...prevState,
                    [contactId]: uData
                };
            });
            props.getLiveChatDataAsync(additionalData.token)
                .then(data => {
                    // save data
                    setUserData(prevState => {
                        let uData = prevState[contactId] || {};
                        uData.tokenData = Object.assign({}, data);
                        return {
                            ...prevState,
                            [contactId]: uData
                        };
                    });
                });
        }

        let customerName = contactData.attributes?.customerName?.value;
        if (!customerName) {
            customerName = "(unknown)";
            showAlert(AlertErrors.COMMON);
        }
        showNotification(
            "Incoming chat: " + customerName,
            "Click here to open the agent panel now."
        );
    };

    useEffect(() => {
        console.log('^$ UserData changed ==> ', userData);
        saveUserData(userData);
    }, [userData]);

    const handleContactConnected = (contact) => {
        try {
            if (contact) {
                initChatSession(contact);

                let contactId = contact.contactId;
                setContacts(prevState => ({
                    ...prevState,
                    [contactId]: getContactData(contact, prevState[contactId]?.additionalData)
                }));
                logInfoEvent("[contact.onConnected] Contact connected to agent. Contact state is " + contact.getStatus().type);
            } else {
                logInfoEvent("[contact.onConnected] Contact connected to agent. Null contact passed to event handler");
            }
        } catch (e) {
            sendErrorLog('Error occurred on contact connected', {message: e.message});
        }
    };

    const handleContactMissed = (contact) => {
        setIncoming(false);
        setContacts(prevState => ({
            ...prevState,
            [contact.contactId]: getContactData(contact, prevState[contact.contactId]?.additionalData)
        }));
        if (!contactsErrorStatus[contact.contactId] && !isContactProcessing[contact.contactId]) {
            setContactsErrorStatus(prevState => ({
                ...prevState,
                [contact.contactId]: 'missed'
            }));
            changeInfoMessage(InfoMessages.CHAT_MISSED_OR_REJECTED);
        }
    };

    const handleContactIncoming = (contact) => {
        try {
            if (contact) {
                logInfoEvent("[contact.onIncoming] Contact is incoming. Contact state is " + contact.getStatus().type);
            } else {
                logInfoEvent("[contact.onIncoming] Contact is incoming. Null contact passed to event handler");
            }
        } catch (e) {
            sendErrorLog('Error occurred on contact incoming', {message: e.message});
        }
    };

    const handleContactAccepted = (contact) => {
        try {
            if (contact) {
                initChatSession(contact);

                let contactId = contact.contactId;
                setContacts(prevState => ({
                    ...prevState,
                    [contactId]: getContactData(contact, prevState[contactId]?.additionalData)
                }));

                setCurrentContact(contactId);
                logInfoEvent("[contact.onAccepted] Contact accepted by agent. Contact state is " + contact.getStatus().type);
            } else {
                logInfoEvent("[contact.onAccepted] Contact accepted by agent. Null contact passed to event handler");
            }
        } catch (e) {
            sendErrorLog('Error occurred on contact accepted', {message: e.message});
        }
    };

    const handleContactEnded = (contact) => {
        try {
            if (contact) {
                const contactId = contact.contactId;
                setContacts(prevState => ({
                    ...prevState,
                    [contactId]: getContactData(contact, prevState[contactId]?.additionalData)
                }));
                setContactsState(prevState => ({
                    ...prevState,
                    [contactId]: ContactState.NORMAL
                }));

                if (chatSession[contactId]) {
                    chatSession[contactId].cleanUpOnParticipantDisconnect();
                }
                setShowInputError({[contactId]:false})

                logInfoEvent("[contact.onEnded] Contact has ended.");
                scrollToBottom()
            } else {
                logInfoEvent("[contact.onEnded] Contact has ended. Null contact passed to event handler");
            }
        } catch (e) {
            sendErrorLog('Error occurred on contact ended', {message: e.message});
        }
    };

    const handleContactDestroy = (contact) => {
        try {
            const contactId = contact?.contactId;
            if (contactId) {
                const removeFromStateObject = (prevState) => {
                    let newState = {...prevState};
                    removeFromObject(newState, contactId);
                    return newState;
                }

                if (currentContact === contactId) {
                    setCurrentContact(null);
                }

                setLoadMoreTranscripts(removeFromStateObject);
                setContactsErrorStatus(removeFromStateObject);
                setContacts(removeFromStateObject);
                setContactsAdditionalData(removeFromStateObject);
                setUserData(removeFromStateObject);
                setContactsState(removeFromStateObject);
                setMessages(removeFromStateObject);
                setUnreadMessages(removeFromStateObject);
                setAgentMessages(removeFromStateObject);
                setSelectedChatCategory(removeFromStateObject);
                setPrescription(removeFromStateObject);
                setImmunization(removeFromStateObject);
                setNurseAdvice(removeFromStateObject);
                setGlp(removeFromStateObject);

                removeFromObject(chatSession, contactId);
                removeFromArray(isContactLoaded, contactId);
                removeFromArray(contactTransferred, contactId);
                clearUnreadMessages(contactId);
            }
        } catch (e) {
            sendErrorLog('Error occurred on contact destroy', {message: e.message});
        }
    };

    const initChatSession = (contact) => {
        const contactId = contact.contactId;

        if (isIn(isContactLoaded, contactId)) {
            return;
        }

        setChatSessionGlobalConfig();

        const agentChatSession = createAgentChatSession(contact);
        chatSession[contactId] = agentChatSession;

        agentChatSession.onConnectionEstablished(event => {
            //isChatLoaded = true;
            //setChatLoaded(true);
            if (!isIn(isContactLoaded, contactId)) {
                isContactLoaded.push(contactId);
            }
        });
        agentChatSession.onMessage(handleIncomingMessage);

        agentChatSession.onEnded(event => {
            const {chatDetails} = event;
            handleContactEnded(chatDetails);
        });

        agentChatSession.onTyping(event => {
            const {chatDetails, data} = event;
            if (data.ParticipantRole === "CUSTOMER") {
                setIsTyping(prevState => ({
                    ...prevState,
                    [contactId]: true
                }));
            }
        });

        agentChatSession.connect().then((response) => {
            agentChatSession.getTranscript({
                maxResults: 100,
                sortOrder: "ASCENDING"
            }).then(data => {
                setMessages(prevState => ({
                    ...prevState,
                    [contactId]: data.data.Transcript
                }));
            });
        }, (error) => {
            showAlert(AlertErrors.NETWORK);
            sendErrorLog('Chat session connection failed', {error: error});
        });
    };

    const sendTypingEvent = (contactId) => {
        try {
            if (chatSession[contactId] && isEnabledMessaging) {
                chatSession[contactId].controller.sendEvent({
                    contentType: "application/vnd.amazonaws.connect.event.typing"
                });
            }
        } catch (e) {
        }
    };

    const sendMessage = () => {
        let agentSendMessage = agentMessages[currentContact]?.input;
        if (agentSendMessage.length>1024){
            return false;
        }
        if (agentSendMessage !== '') {
            updateAgentMessage('');
        }
        if (chatSession[currentContact] && !checkEmptyInputText(agentSendMessage)) {
            // send message
            try {
                chatSession[currentContact].controller.sendMessage({
                    contentType: "text/plain",
                    message: agentSendMessage
                }).then(() => {
                    let templateParams = templateUsageStatistic;
                    if (templateParams && templateParams.templateId) {
                        templateParams.isModified = templateParams.text !== agentSendMessage;
                        api.sendTemplateUsageStatistic(templateParams, agentChatConfiguration);
                    }
                });
            } catch (e) {
                sendErrorLog('Error occurred on send message', {message: e.message});
            }
            // reset typing event
            setAgentMessages(prev => {
                if (prev[currentContact]) {
                    prev[currentContact].lastSendTypingEvent = 0;
                }
                return prev;
            });
            scrollToBottom();
        }
    };

    const updateAgentMessage = (message) => {
        const currentDate = new Date();
        setCurrentAgentMessage(message);
        setAgentMessages(prev => {
            if (prev[currentContact]) {
                prev[currentContact].input = message;
            } else {
                prev[currentContact] = {
                    input: message,
                    lastSendTypingEvent: 0,
                }
            }
            try {
                if (!checkEmptyInputText(message) && currentDate - (prev[currentContact]?.lastSendTypingEvent || 0) >= TIMEOUT_TYPING_EVENT) {
                    prev[currentContact].lastSendTypingEvent = currentDate;
                    sendTypingEvent(currentContact);
                }
            } catch (e) {
            }
            return prev;
        });
        if (!message) {
            setTemplateUsageStatistic({}); // reset template usage statistic if field cleared
        }
    };

    const handleKeyDown = (event) => {
        if (event.shiftKey === true && event.code === "Enter") { // 13 is the key code for "enter"
            setCurrentAgentMessage(currentAgentMessage + '\n'); // add a new line character to the input value
            event.preventDefault(); // prevent the default "enter" behavior (i.e. submitting a form)
        }
        if ((event.code === "Enter" || event.code === "NumpadEnter")&& event.shiftKey === false) {
            event.preventDefault();
            sendMessage();
        }
    };

    const handleIncomingMessage = (e) => {
        try {
            const contactId = e.chatDetails.contactId;

            sendInfoLog(`Agent received message [${e.data.Id}]`, {
                messageId: e.data.Id,
                contactId: e.data.ContactId,
                initialContactId: e.data.InitialContactId,
                type: e.data.Type,
                role: e.data.ParticipantRole
            });

            if (e.data && e.data.Type === 'EVENT' && e.data.ContentType === 'application/vnd.amazonaws.connect.event.transfer.failed') {
                removeFromArray(contactTransferred, contactId);
                setContactsState(prevState => ({
                    ...prevState,
                    [contactId]: ContactState.NORMAL
                }));
            }

            setMessages(prevState => ({
                ...prevState,
                [contactId]: prevState[contactId] !== undefined &&
                prevState[contactId] !== null && Array.isArray(prevState[contactId]) ?
                    prevState[contactId].concat(e.data) : [e.data]
            }));

            setIsTyping(prevState => ({
                ...prevState,
                [contactId]: false
            }));

            if (!windowFocused
                && e.data.Type === "MESSAGE"
                && e.data.ParticipantRole === "CUSTOMER"
                && e.data.Id !== lastMessageId
                && !isIn(contactTransferred, contactId)
            ) {
                playSound(messageSound);
            }

            if (e.data.ParticipantRole === "CUSTOMER"
                && (!windowFocused || currentContact !== contactId)
                && e.data.Type === "MESSAGE"
                && !isIn(contactTransferred, contactId)
            ) {
                setUnreadMessages(prevState => {
                    if (prevState[contactId] && isIn(prevState[contactId], e.data.Id)) {
                        return prevState;
                    }
                    return ({
                        ...prevState,
                        [contactId]: prevState[contactId] ? prevState[contactId].concat(e.data.Id) : [e.data.Id]
                    });
                });
            }

            lastMessageId = e.data.Id;
        } catch (e) {
            sendErrorLog('Error occurred on incoming message', {message: e.message});
        }

        scrollToBottom();
    };

    const subscribeToAgentEvents = (agent) => {
        try {
            setAgentName(agent.getName());
            window.myCPP.agent = agent;
            logInfoMsg("Subscribing to events for agent " + agent.getName());
            logInfoMsg("Agent is currently in status of " + agent.getStatus().name);
            changeByAgentStatus(agent.getStatus().name);

            const agentContacts = agent.getContacts();

            agentContacts.forEach((contact) => {
                const contactId = contact.contactId;
                setContacts(prevState => ({
                    ...prevState,
                    [contactId]: getContactData(contact, contactsAdditionalData[contactId] /*prevState[contactId]?.additionalData*/)
                }));
            });

            const allQueueARNs = agent.getAllQueueARNs();
            agent.getEndpoints(allQueueARNs,
                {
                    success: (data) => {
                        setEndpoints(data.endpoints);
                    },
                    failure: () => {
                        sendErrorLog('Can\'t get available endpoints');
                    }
                });

            agent.onWebSocketConnectionLost((agent) => {
                showAlert(AlertErrors.NETWORK);
                sendErrorLog('Web socket connection lost');
            });
            agent.onWebSocketConnectionGained(() => {
                setAlertError(null);
                setAppState(AppState.NORMAL);
            });

            agent.onRefresh(handleAgentRefresh);
            agent.onRoutable(handleAgentRoutable);
            agent.onNotRoutable(handleAgentNotRoutable);
            agent.onOffline(handleAgentOffline);
            agent.onError(handleAgentError);
        } catch (e) {
            sendErrorLog('Error occurred on subscribing agent events', {message: e.message});
        }
    };

    const handleAgentRefresh = (agent) => {
        try {
            logInfoEvent("[agent.onRefresh] Agent data refreshed. Agent status is " + agent.getStatus().name);
            changeByAgentStatus(agent.getStatus().name);
            setAgentName(agent.getName());
        } catch (e) {
            sendErrorLog('Error occurred on handle refresh', {message: e.message});
        }
    };

    const handleAgentRoutable = (agent) => {
        try {
            logInfoEvent("[agent.onRoutable] Agent is routable. Agent status is " + agent.getStatus().name);
            changeByAgentStatus(agent.getStatus().name);
        } catch (e) {
            sendErrorLog('Error occurred on set routable state', {message: e.message});
        }
    };

    const handleAgentNotRoutable = (agent) => {
        try {
            logInfoEvent("[agent.onNotRoutable] Agent is online, but not routable. Agent status is " + agent.getStatus().name);
            changeByAgentStatus(agent.getStatus().name);
        } catch (e) {
            sendErrorLog('Error occurred on set not-routable state', {message: e.message});
        }
    };

    const handleAgentOffline = (agent) => {
        try {
            logInfoEvent("[agent.onOffline] Agent is offline. Agent status is " + agent.getStatus().name);
            changeByAgentStatus(agent.getStatus().name);
        } catch (e) {
            sendErrorLog('Error occurred on set offline state', {message: e.message});
        }
    };

    const handleAgentError = (agent) => {
        logInfoEvent("[agent.onError] Error: ", agent);
        sendErrorLog('Agent session error');
    };

    const goAvailable = () => {
        const agent = window.myCPP?.agent;
        if (agent) {
            try {
                if (agent.getState().type === connect.AgentStateType.ROUTABLE) {
                    return;
                }
                let routableState = agent.getAgentStates().filter((state) => {
                    return state.type === connect.AgentStateType.ROUTABLE;
                })[0];
                agent.setState(routableState, {
                    success: () => {
                        logInfoMsg("Set agent status to Available (routable) via Streams")
                    },
                    failure: () => {
                        logInfoMsg("Failed to set agent status to Available (routable) via Streams");
                        sendErrorLog('Failed to set agent status to Available (routable) via Streams');
                    }
                });
            } catch (e) {
                sendErrorLog('Error occurred on go available', {message: e.message});
            }
        }
    };

    const goOffline = () => {
        const agent = window.myCPP?.agent;
        if (agent) {
            try {
                if (agent.getState().type !== connect.AgentStateType.OFFLINE
                    && canAgentChangeStatus()
                ) {
                    let offlineState = agent.getAgentStates().filter((state) => {
                        return state.type === connect.AgentStateType.OFFLINE;
                    })[0];
                    agent.setState(offlineState, {
                        success: () => {
                            logInfoMsg("Set agent status to Offline via Streams")
                            setMessages({});
                            setCanOffline(true);
                        },
                        failure: () => {
                            logInfoMsg("Failed to set agent status to Offline via Streams");
                            sendErrorLog('Failed to set agent status to Offline via Streams');
                            setInfoMessage(null)
                        }
                    });
                    setCurrentContact(null);
                } else {
                    setCanOffline(false);
                    changeInfoMessage(InfoMessages.CANT_GO_OFFLINE);
                }
            } catch (e) {
                sendErrorLog('Error occurred on go offline', {message: e.message});
            }
        }
    };

    const changeInfoMessage = (message) => {
        setInfoMessage(message);
    }

    const canAgentChangeStatus = () => {
        return Object.keys(contacts).every(contact => !contacts[contact].connected) && !incoming;
    };

    const acceptContact = () => {
        const contact = window.myCPP?.contact;
        if (contact) {
            contact.accept({
                success: () => {
                    logInfoMsg("Accepted contact via Streams");
                },
                failure: () => {
                    logInfoMsg("Failed to accept contact via Streams");
                    sendErrorLog("Failed to accept contact via Streams");
                }
            });
            setIncoming(false);
        } else {
            sendErrorLog('Error occurred on accept contact (null or undefined)');
        }
    };

    const rejectContact = (callback) => {
        const contact = window.myCPP?.contact;
        if (contact) {
            const contactId = contact.contactId;
            callback(true); //setContactProcessing
            isContactProcessing[contactId] = true;
            stopSound(contactSound);
            const finallyFunc = () => {
                removeFromObject(isContactProcessing, contactId);
                callback(false); //setContactProcessing
            }
            contact.reject({
                success: () => {
                    setIncoming(false);
                    logInfoMsg("Rejected contact via Streams");
                    setContactsErrorStatus(prevState => ({
                        ...prevState,
                        [contactId]: 'rejected'
                    }));
                    changeInfoMessage(InfoMessages.CHAT_MISSED_OR_REJECTED);
                    finallyFunc();
                },
                failure: () => {
                    logInfoMsg("Failed to reject contact via Streams");
                    sendErrorLog("Failed to reject contact via Streams");
                    finallyFunc();
                }
            });
        } else {
            sendErrorLog('Error occurred on reject contact (null or undefined)');
        }
    };

    const endChat = (contactId) => {
        try {
            const targetContactId = contactId || currentContact;
            if (targetContactId && !isIn(contactTransferred, targetContactId)) {
                contacts[targetContactId].connection.getAgentConnection().destroy();
            }
        } catch (e) {
            sendErrorLog('Error occurred on end chat', {message: e.message});
        }
    };

    const onEndChat = (contactId) => {
        if (contactId && contactId !== currentContact) {
            disconnectChat(contactId);
            return;
        }
        const selectedCategories = loadSelectedCategory();
        if (enableDropDownCategories &&
            (
                !selectedCategories[currentContact]
                || (selectedCategories[currentContact] && !selectedCategories[currentContact].saved)
            )
        ) {
            setRenderDropDownCategories(true);
            return;
        }
        disconnectChat(contactId);
    };

    const disconnectContact = (keyname) => {
        if (keyname) {
            let canClearConnection;
            try {
                // lifehack: prevent error on connection clear(), if connection not exist in connect-streams
                contacts[keyname].connection.getStatus();
                canClearConnection = true;
            } catch (e) {}
            try {
                if (canClearConnection) {
                    contacts[keyname].connection.clear();
                } else {
                    handleContactDestroy(contacts[keyname].connection);
                }
            } catch (e) {
                sendErrorLog('Error occurred on disconnect contact', {message: e.message});
            }

            setMessages(prevState => ({
                ...prevState,
                [keyname]: []
            }));

            if (keyname === currentContact) {
                setCurrentContact(null);
            }
        }
    };

    // Terminate Agent chat
    const terminateChat = () => {
        logInfoMsg('Terminate Connect Streams objects & events')
        try {
            connect.core.terminate();
            if (window.myCPP?.containerDiv) {
                const iframe = window.myCPP.containerDiv.firstElementChild;
                if (iframe) {
                    window.myCPP.containerDiv.removeChild(iframe);
                }
                window.myCPP.containerDiv.innerHTML = '';
                window.myCPP = {};
            }
        } catch (e) {
            sendErrorLog('Error occurred on chat termination', {message: e.message});
        }
    };

    const changeQueue = (number) => {
        try {
            const quick = endpoints[number];
            // contacts[currentContact].attributes.previousQueueName = currentQueueName;
            console.log('^contacts[currentContact]', contacts[currentContact]);
            const targetContact = contacts[currentContact];
            setHideDropdown(false);
            targetContact.connection.addConnection(quick, {
                success: (data) => {
                    contactTransferred.push(currentContact);
                    targetContact.additionalData.chatEndedByAgent = true;
                    setContacts(prevState => ({
                        ...prevState,
                        [currentContact]: targetContact
                    }));
                },
                failure: (data) => {
                    logInfoMsg("Transfer failed");
                    sendErrorLog('Transfer failed', {
                        currentContact: currentContact,
                        quickEndpoint: quick,
                    });
                }
            });
        } catch (e) {
            sendErrorLog('Error occurred on change queue', {message: e.message});
        }
    };

    const changeAgentMessage = (e) => {
        clearUnreadMessages(currentContact)
        const agentSendMessage = e?.target?.value || '';

        setShowInputError( prevState => ({...prevState, [currentContact]: agentSendMessage.length > 1024}));

        updateAgentMessage(agentSendMessage);
    };

    const setTemplate = (modalData) => {
        if (modalData.parameters) {
            modalData.htmlText = replaceTemplateModalText(modalData)
            setTemplateModalData((prevState) => {
                return ({
                    ...prevState,
                    show: true,
                    ...modalData
                })
            })
        } else {
            setTemplateMessage(modalData.text,true)
        }
        setTemplateUsageStatistic({
            contactId: currentContact,
            agentUsername: agentConfiguration?.username,
            templateId: modalData.id,
            isModified: false,
            text: modalData.text
        });
    };

    const replaceTemplateModalText = (modalData,inputValues={}) =>{
        let text = modalData.text
        modalData.parameters !== undefined && modalData.parameters.map((item)=>{
            text = text.replace("{"+item.variable+"}","<span class='template-variable'>"+(inputValues[item.variable]!==undefined && inputValues[item.variable].length?inputValues[item.variable]:item.title)+"</span>")
        })
        return text
    }

    const setTemplateMessage = (text, rewriteMessage = false) => {
        let messageText = text;
        if (agentMessages[currentContact]?.input) {
            if (!rewriteMessage) {
                messageText = `${messageText} ${agentMessages[currentContact].input}`;
            }
        }
        setTemplateUsageStatistic(prevState => ({
            ...prevState,
            text: messageText
        }));
        updateAgentMessage(messageText);
        textArea.current.focus();
    }

    const getAgentsTransferNote = useCallback((contact) => {
        const attributes = contact?.attributes || {}
        const keys = Object.keys(attributes)
        return keys.length > 0
            ? keys
                .filter(key => key.includes('transferredNote'))
                .filter(key => !key.includes(currentContact))
                .reduce((acc, item) => {
                    acc[item.split('_')[1]] = JSON.parse(Buffer.from(attributes[item]?.value ?? '', 'base64').toString('utf-8'))
                    return acc
                }, {})
            : {}
    }, [currentContact])

    const getDobThanksMessage = () => {
        const messageTime = new Date();
        return  {
            ParticipantRole: 'AGENT',
            Type: 'MESSAGE',
            ContentType: 'text/plain',
            Content: i18n.__("Thanks! Give me one moment to review your request."),
            AbsoluteTime: messageTime.toISOString()
        }
    }

    let filteredMessages = [],
        lastId = null;

    if (messages[currentContact] && messages[currentContact].length !== undefined) {
        let agentParticipantId = null;
        let customerInfo = null;
        let referralInfoShown = false;
        let oldReferralInfo = false;
        let prescriptionRefillRequestShown = false;
        let immunizationRequestShown = false;
        let nurseAdviceRequestShown = false;
        let contactDataAttributesShown = false;
        let glpRequestShown = false;
        let ignoreMessagesState = false;
        let usedQueueName;
        const agentsTransferNote = getAgentsTransferNote(contacts[currentContact]);
        let isChatTransferred = false;
        let nextAgentAutoMessageShow = null;
        let seriesContactId = null

        messages[currentContact].forEach((entry, entryIndex, _entries) => {
            if (entry.DisplayName === 'BOT' || entry.DisplayName === 'SYSTEM_MESSAGE') {
                try {
                    let messageJson = JSON.parse(entry.Content);
                    if (messageJson) {
                        if (messageJson.type === 'EVENT') {
                            if (messageJson.queueName) {
                                usedQueueName = messageJson.queueName;
                            }
                            if (messageJson.chatTransfer === true) {
                                ignoreMessagesState = true;
                                if (!isIn(contactTransferred, currentContact)) {
                                    contactTransferred.push(currentContact);
                                }
                            }
                        }
                        if (messageJson.type === 'EVENT'
                            && (messageJson.customerName || messageJson.customerDOB)
                        ) {
                            let _name = messageJson.customerName;
                            if (!_name && customerInfo?.name) {
                                _name = customerInfo?.name;
                            } else if (!_name && userData[currentContact]?.tokenData?.patientFullName) {
                                _name = userData[currentContact].tokenData.patientFullName
                            } else if (!_name && contacts[currentContact]?.attributes?.customerName?.value) {
                                _name = contacts[currentContact]?.attributes?.customerName?.value;
                            }
                            try {
                                customerInfo = {
                                    name: _name,
                                    DOB: messageJson.customerDOB,
                                    phone: messageJson.customerPhone,
                                };
                            } catch (e) {}
                        }
                        if (messageJson.customerName
                            && messageJson.customerDOB
                            && messageJson.additionalData
                            && !prescription[currentContact]
                        ) {
                            setPrescription(prevState => ({
                                ...prevState,
                                [currentContact]: messageJson
                            }));
                        }
                        if (messageJson.customerName
                            && messageJson.additionalData
                            && !immunization[currentContact]
                        ) {
                            setImmunization(prevState => ({
                                ...prevState,
                                [currentContact]: messageJson
                            }));
                        }
                        if (
                            messageJson.additionalData
                            && typeof messageJson.additionalData === 'string'
                            && messageJson.additionalData.search('problem') !==-1
                            && !nurseAdvice[currentContact]
                        ) {
                            setNurseAdvice(prevState => ({
                                ...prevState,
                                [currentContact]: messageJson
                            }));
                        }
                        if (
                            messageJson.additionalData
                            && typeof messageJson.additionalData === 'string'
                            && messageJson.additionalData.search('problem') !==-1
                            && !glp[currentContact]
                        ) {
                            setGlp(prevState => ({
                                ...prevState,
                                [currentContact]: messageJson
                            }));
                        }
                        if (messageJson.type === 'EVENT'
                            && !messageJson.customerName
                            && !messageJson.customerDOB
                            && (messageJson?.additionalData?.mrn || messageJson?.additionalData?.token)
                        ) {
                            const {mrn, department, ref, queue} = messageJson?.additionalData;
                            oldReferralInfo = {mrn: mrn, department: department, ref: ref, queue: queue};
                        }
                    }
                } catch (e) {
                }
            }

            entry.usedQueueName = usedQueueName;

            if (entry.ParticipantRole === 'AGENT'
                && entry.Type === 'EVENT'
            ) {
                if (entry.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined') {
                    if (isIn(contactTransferred, currentContact)) {
                        removeFromArray(contactTransferred, currentContact);
                    }
                    if (agentChatConfiguration?.agentHelloMessage) {
                        entry.agentHelloMessage = agentChatConfiguration.agentHelloMessage?.byQueue[usedQueueName];
                        if (!entry.agentHelloMessage) {
                            entry.agentHelloMessage = agentChatConfiguration?.agentHelloMessage?.default;
                        }
                    }
                    if (customerInfo) {
                        entry.customerInfo = {...customerInfo};
                    }
                    if ((userData[currentContact] || oldReferralInfo) && !referralInfoShown) {
                        try {
                            let data = {...userData[currentContact], ...oldReferralInfo};
                            if (data.token || data.mrn) {
                                referralInfoShown = true;
                                entry.referralInfo = data;
                            }
                        } catch (e) {}
                    }
                    if (prescription[currentContact] && !prescriptionRefillRequestShown) {
                        try {
                            let data = prescription[currentContact];
                            if (typeof data.additionalData === 'string') {
                                try {
                                    data.additionalData = JSON.parse(data.additionalData);
                                } catch (e) {
                                    data.additionalData = {};
                                }
                            }
                            if (data.additionalData.prescription) {
                                prescriptionRefillRequestShown = true;
                                entry.prescriptionRefillRequest = data;
                            }
                        } catch (e) {
                        }
                    }
                    if (immunization[currentContact] && !immunizationRequestShown) {
                        try {
                            let data = immunization[currentContact];
                            if (typeof data.additionalData === 'string') {
                                try {
                                    data.additionalData = JSON.parse(data.additionalData);
                                } catch (e) {
                                    data.additionalData = {};
                                }
                            }
                            if (data.additionalData.immunization) {
                                immunizationRequestShown = true;
                                entry.immunizationRequest = data;
                            }
                        } catch (e) {
                        }
                    }
                    if (nurseAdvice[currentContact] && !nurseAdviceRequestShown) {
                        try {
                            let data = nurseAdvice[currentContact];
                            if (typeof data.additionalData === 'string') {
                                try {
                                    data.additionalData = JSON.parse(data.additionalData);
                                } catch (e) {
                                    data.additionalData = {};
                                }
                            }
                            if (data.additionalData.nurseAdvice) {
                                nurseAdviceRequestShown = true;
                                entry.nurseAdviceRequest = data;
                            }
                        } catch (e) {
                        }
                    }
                    if (contacts[currentContact]?.attributes?.CustomIdentifier && !contactDataAttributesShown){
                        entry.getContactDataAttributes = () => {
                            if (!entry.contactDataAttributes) {
                                entry.contactDataAttributes = api.getContactData({
                                    cmd: 'getAttributes',
                                    apiKey: contacts[currentContact]?.attributes?.practiceId?.value,
                                    sessionId: contacts[currentContact]?.attributes?.CustomIdentifier?.value
                                }, agentChatConfiguration);
                            }
                            return entry.contactDataAttributes;
                        }
                        contactDataAttributesShown = true;
                    }
                    if (glp[currentContact] && !glpRequestShown) {
                        try {
                            let data = nurseAdvice[currentContact];
                            if (typeof data.additionalData === 'string') {
                                try {
                                    data.additionalData = JSON.parse(data.additionalData);
                                } catch (e) {
                                    data.additionalData = {};
                                }
                            }
                            if (data.additionalData.glp) {
                                glpRequestShown = true;
                                entry.glpRequest = data;
                            }
                        } catch (e) {
                        }
                    }
                    if (isChatTransferred && (usedQueueName === 'Billing' || usedQueueName === 'Billing ES')) {
                        nextAgentAutoMessageShow = getDobThanksMessage();
                    }
                }
            }

            if(entry.Type === 'EVENT' && entry.ContentType === 'application/vnd.amazonaws.connect.event.transfer.succeeded') {
                isChatTransferred = true
            }

            if (entry.Type === 'EVENT'
                || entry.ContentType === 'application/vnd.amazonaws.connect.event.transfer.failed'
                || entry.ContentType === 'application/vnd.amazonaws.connect.event.transfer.succeeded'
                || (
                    entry.ParticipantRole === 'AGENT' && entry.ContentType === 'application/vnd.amazonaws.connect.event.participant.left'
                )
            ) {
                ignoreMessagesState = false;
            }

            let ignoreMessage = (
                lastId === entry.Id
                || entry.ContentType === 'application/vnd.amazonaws.connect.event.transfer.failed'
                || (
                    entry.ParticipantRole === 'AGENT'
                    && entry.Type === 'EVENT'
                    && entry.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined'
                    && entry.ParticipantId === agentParticipantId
                )
                || ignoreMessagesState
            );

            if (!ignoreMessage) {
                filteredMessages.push(entry);
            }

            if (entry.ParticipantRole === 'CUSTOMER'
                && entry.Type === 'MESSAGE'
            ) {
                if (nextAgentAutoMessageShow) {
                    filteredMessages.push(nextAgentAutoMessageShow);
                    nextAgentAutoMessageShow = null;
                }
            }

            lastId = entry.Id;
            if (entry.ParticipantRole === 'AGENT'
                && entry.Type === 'EVENT'
                && entry.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined'
                && entry.ParticipantId !== agentParticipantId
            ) {
                agentParticipantId = entry.ParticipantId;
            }

            if (entry.ContactId !== seriesContactId) {
                if (seriesContactId in agentsTransferNote) {
                    _entries[entryIndex - 1]['agentTransferNote'] = agentsTransferNote[seriesContactId]
                }

                seriesContactId = entry.ContactId;
            }
        });
    }

    /*
    if (isEnabledMessaging && isIn(contactTransferred, currentContact)) {
        setTimeout(() => {
            setIsEnabledMessaging(false); // ToDo: need to rework with customer chat & Connect flow
            setContactsState(prevState => ({
                ...prevState,
                [currentContact]: contacts[currentContact]?.state?.type !== 'ended' ? ContactState.TRANSFERRING : ContactState.NORMAL
            }));
        });
    }
    */

    const AgentOverlay = () => {
        let result = <></>;
        const AgentOverlayDiv = ({children}) => {
            return <div className={"agent-overlay"} style={{width: "100%", height: "inherit", left: 0}}>{children}</div>;
        }
        switch (appState) {
            case AppState.RELOAD:
                result = <AgentOverlayDiv>
                    <LoaderOval
                        color="#d7d7d7"
                        height={60}
                        width={60}
                        timeout={0}
                    />
                </AgentOverlayDiv>
                break;
            case AppState.ERROR:
                result = <AgentOverlayDiv>
                    <h4>Error. Please press "Refresh page" button.</h4>
                </AgentOverlayDiv>
                break;
        }
        return result;
    };
    console.log('!!!!',showInputError[currentContact])
    return (
        <>
            <div id="agent-ccp-container" style={{width: '0', height: '0', display: 'none'}}/>
            <AgentOverlay />
            <div className="chat-window container"  ref={containerRef}>
                <Header
                    agentChatConfiguration={agentChatConfiguration}
                    agentConfiguration={agentConfiguration}
                    goAvailable={goAvailable}
                    goOffline={goOffline}
                    contacts={contacts}
                    currentContact={currentContact}
                    currentContactState={contactsState[currentContact]}
                    reloadChat={reloadChat}
                    agentName={agentName}
                    changeQueue={changeQueue}
                    endpoints={endpoints}
                    setEndpoints={setEndpoints}
                    emptyMessages={!filteredMessages.length}
                    activeContacts={activeContacts}
                    onEndChat={onEndChat}
                    templateModalData={templateModalData}
                    setTemplateModalData={setTemplateModalData}
                    setTemplateMessage={setTemplateMessage}
                    replaceTemplateModalText={replaceTemplateModalText}
                />
                <div className="row chat-body">
                    <CustomAlert
                        alert={alertError}
                        setAlert={setAlertError}
                        variant={"danger"}
                    />
                    <>
                        <CustomModal
                            show={enableDropDownCategories && renderDropDownCategories}
                            setShow={setRenderDropDownCategories}
                        >
                            <DropDownChatCategories
                                chatCategories={agentChatConfiguration.chatCategories}
                                selectedChatCategory={selectedChatCategory}
                                setSelectedChatCategory={setSelectedChatCategory}
                                setRenderDropDownCategories={setRenderDropDownCategories}
                                agentConfiguration={agentConfiguration}
                                contacts={contacts}
                                instanceId={agentChatConfiguration.instanceId}
                                currentContact={currentContact}
                                disconnectChat={disconnectChat}
                                config={{
                                    xApiUrl: agentChatConfiguration.xApiUrl,
                                    xApiKey: agentChatConfiguration.xApiKey
                                }}
                            />
                        </CustomModal>
                        <Contacts contacts={contacts}
                                  currentContact={currentContact}
                                  unreadMessages={unreadMessages}
                                  setCurrentContact={setCurrentContact}
                                  incoming={incoming}
                                  incomingContact={incomingContact}
                                  rejectContact={rejectContact}
                                  acceptContact={acceptContact}
                                  messages={messages}
                                  agentName={agentName}
                                  showAlert={showAlert}
                                  contactsErrorStatus={contactsErrorStatus}
                                  onEndChat={onEndChat}
                                  userData={userData}
                        />

                        <div className="col-md-8 conversation-row"
                             onClick={() => clearUnreadMessages(currentContact)}>
                            <div className="conversation">
                                <LoadTranscripts
                                    currentContact={currentContact}
                                    currentContactState={contactsState[currentContact]}
                                    loadTranscript={loadTranscript}
                                    contacts={contacts}
                                    messages={messages}
                                    loadMoreTranscripts={loadMoreTranscripts}
                                />
                                {/*<div className={"conversation-overlay " + (contactsState[currentContact] === ContactState.TRANSFERRING ? "" : "d-none")}>*/}
                                {/*    <div className={"overlay-content"}>*/}
                                {/*        Chat transferring <br/><br/>*/}
                                {/*        <LoaderThreeDots*/}
                                {/*            color="#d7d7d7"*/}
                                {/*            height={60}*/}
                                {/*            width={60}*/}
                                {/*            timeout={0}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="chat-messages">
                                    <Messages
                                        contact={contacts[currentContact]}
                                        messages={filteredMessages}
                                        agentName={agentName}
                                        isTyping={isTyping[currentContact]}
                                        showAlert={showAlert}
                                        currentContact={contacts[currentContact] || {}}
                                        userData={userData[currentContact] || {}}
                                    />
                                </div>
                                <div className={"chat-messages-send -row " + (
                                    isEnabledMessaging?
                                        ''
                                        :
                                        'disabled'
                                )}
                                >
                                    <div className={!isTemplatesAvailable ? "d-none":"-col-md-2 template-button"}>
                                        {
                                            isTemplatesAvailable &&
                                            <Templates
                                                setTemplate={setTemplate}
                                                commonAnswers={agentChatConfiguration.commonAnswers}
                                                queueName={currentQueueName}
                                                hideDropdown={hideDropdown}
                                            />
                                        }
                                    </div>
                                    <div
                                        className={isTemplatesAvailable ? "-col-md-10 message" : "-col-md-12 message"}
                                        onClick={() => {
                                            textArea && textArea.current.focus();
                                        }} >
                                        {showInputError[currentContact]&&<label className={"error"}>Message must be less than 1025 characters</label>}
                                        <div className="inputArea">
                                            <div className={"col-11 inputBox "+(showInputError[currentContact]?"error":"")}>
                                                <TextareaAutosize
                                                    ref={textArea}
                                                    value={currentAgentMessage}
                                                    onHeightChange={scrollToBottom}
                                                    placeholder="Type your message here"
                                                    onChange={changeAgentMessage}
                                                    onKeyDown={handleKeyDown}
                                                    disabled={!isEnabledMessaging}
                                                    maxRows={6}
                                                />
                                            </div>

                                            <div className="col-1 buttonBox">
                                                <div className="send-message d-flex justify-content-center px-2" onClick={sendMessage}>
                                                    <i className="fas fa-paper-plane" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
});

export default AgentChat;
