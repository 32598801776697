import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const useStatusStore = create(
  devtools(
    immer((set, get) => ({
      status: 'OFFLINE',
      changeByAgentStatus: (agentStatus) =>
        set((state) => {
          state.status = agentStatus === 'Available' ? 'ONLINE' : 'OFFLINE';
        }),
      isOnline: () => {
        return get().status === 'ONLINE';
      },
      getFormattedStatus: () => {
        const status = get().status;
        return status[0].toUpperCase() + status.slice(1).toLowerCase();
      },
    })),
  ),
);

export default useStatusStore;
