import React, {useCallback, useEffect, useRef, useState} from 'react'
import AgentChat from "./components/AgentChat";
import 'bootstrap/dist/css/bootstrap.min.css';
import DebugMenu from "./components/common/DebugMenu/DebugMenu";
import {
	AgentChatConfigStoragePath,
	getAgentChatConfigName,
	setAgentChatConfigName
} from "./configs/configHelper";
import {getDebugLoginUrl, isDebugEnabled} from "./utils/helpers";
import CustomModal from "./components/common/CustomModal/CustomModal";
import ChangeRoutingProfile from "./components/debug/ChangeRoutingProfile/ChangeRoutingProfile";

const App = () => {
	const [clientId, setClientId] = useState(getAgentChatConfigName());
	const [showRoutingProfiles, setShowRoutingProfiles] = useState(false);
	const [debugButtons, setDebugButtons] = useState([]);
        const [isShow, setIsShow] = useState(true);

	const agentChatRef = useRef();

	const getConfigSelectOptions = () => {
		if (isDebugEnabled()) {
			return Object.keys(AgentChatConfigStoragePath).map((key, idx) => {
				return <option key={idx} value={key}>{AgentChatConfigStoragePath[key]?.title || ('Client Id: ' + key)}</option>;
			});
		} else {
			if (clientId !== "default") {
				setAgentChatConfigName('');
			}
			return <option value="default">{AgentChatConfigStoragePath["default"]?.title || 'Default'}</option>
		}
	};

	const ConfigPanel = () => {
		return <>
			Config&nbsp;&nbsp;
			<select style={{width: 220, marginRight: 30}}
					value={clientId || ''}
					onChange={(e) => {
						const value = e.target.value;
						setAgentChatConfigName(value);
						if (value) {
							setClientId(value);
						} else {
							window.location.reload();
						}
					}}>
				<option value=''>---</option>
				{getConfigSelectOptions()}
			</select>
		</>
	};

	const updateDebugButtons = () => {
		let buttons = [];
		buttons.push(
			{title: 'Show Common Error', onClick: () => agentChatRef.current.doShowError()},
			{title: 'Show Network Error', onClick: () => agentChatRef.current.doShowNetworkError()},
			{title: 'Show Debug Info', onClick: () => agentChatRef.current.doShowDebugInfo()},
			{title: 'Download Log', onClick: () => window.connect.getLog().download()},
            {title: 'Toggle chat visibility', onClick: handleToggleChatVisiblity},
            {title: 'Logout', onClick: logoutUser},
			{title: '---'}
		);

		if (parseInt(clientId, 10) === 80000001) {
			buttons.push(
				{title: 'Change Routing Profile', onClick: () => setShowRoutingProfiles(true)},
				{title: '---'}
			);
		}

		buttons.push({title: 'Terminate Chat', onClick: () => window.confirm("Terminate chat?") && agentChatRef.current.doTerminate()});

		setDebugButtons(buttons);
	}

	// Test function
	/*
	const getLiveChatDataAsync = async (responseToken) => {
		return {
			"patientMrn":"1234567",
			"patientFullName":"Nick FakeName (token: " + responseToken + ")",
			"referralTeam": "The referral team",
			"referralExtId":"7890123456"
		};
	};
	*/
	const getLiveChatDataAsync = async (responseToken) => {
		console.log("^$ getLiveChatDataAsync -> " + responseToken);
		const _tr = new Promise((resolve) => {
			setTimeout(() => {
				console.log("^$ getLiveChatDataAsync (resolved) -> " + responseToken);
				resolve({
					"patientMrn":"1234567",
					"patientFullName":"Nick FakeName (token: " + responseToken + ")",
					"referralTeam": "The referral team",
					"referralExtId":"7890123456"
				});
			}, 10000);
		});
		return _tr;

		// let result = {};
		// await _tr.then(data => result = data);
		// return result;
	};

	useEffect(() => {
		updateDebugButtons();
	}, [clientId]);

	const logoutUser = () => {
		const result = agentChatRef.current.doLogoutAgent()
		console.warn('check is can logout agent', result)
	}

	const handleToggleChatVisiblity = useCallback(() => {
		setIsShow(prev => !prev)
	}, [])

	return (
		<div className="main">
			<div className="container-fluid">
				<div className="panel-dev">
					{
						isDebugEnabled() ? <DebugMenu buttons={debugButtons} /> : ''
					}
				</div>
				<CustomModal show={showRoutingProfiles} setShow={setShowRoutingProfiles}>
					<ChangeRoutingProfile setShowModal={setShowRoutingProfiles}	testApiKey={"mwLTy_og78cwf%28O%5E%2ATY%3Bko"}/>
				</CustomModal>

				<div className="additional-panel container"
					 style={{
						 textAlign: "right",
						 padding: "19px 30px 0 0",
						 position: "absolute",
						 right: "0px"
					 }}
				>
					<ConfigPanel />
					<span
						onClick={() => agentChatRef.current ? agentChatRef.current.doReloadChat() : false}
						style={{fontSize: "12px", color: "#888", cursor: "pointer", zIndex: 1}}
					>
							<i className="fa fa-undo" /> Refresh page
					</span>
				</div>

				<div style={{textAlign: "center", margin: "0 0 15px 0"}}>
					{
						!isDebugEnabled() ?
							<img src="/images/default-logo.svg" style={{width: "270px"}}/>
							:
							<div style={{height: "70px"}} />
					}
				</div>
				<div className={`${isShow ? 'visible-test' : 'hide-test' }`} style={{height: "780px"}}>
					{
						clientId ?
							<AgentChat
								ref={agentChatRef}
								clientId={clientId}
								loginUrl={getDebugLoginUrl() ? getDebugLoginUrl() : process.env.REACT_APP_LOGIN_URL}
								getLiveChatDataAsync={getLiveChatDataAsync}
							/>
							:
							<div style={{width: "100%", textAlign: "center"}}>Agent Chat config is incorrect or unavailable</div>
					}
				</div>
			</div>
		</div>
	);
};

export default App;
