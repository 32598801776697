import { useEffect, useState } from 'react';
import useStatusStore from '../stores/Status';

export const useAgentConfiguration = () => {
	const [agentConfiguration, setAgentConfiguration] = useState({});

	useEffect(() => {
		useStatusStore.subscribe((state) => {
			if (state.status === 'ONLINE') {
				setAgentConfiguration(window.myCPP.agent.getConfiguration());
			}
		});
	}, []);

	return agentConfiguration;
};
