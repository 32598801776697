import React from 'react';
import style from "./InfoMessage.module.css"

function InfoMessage({
                         message,
                         setMessage,
                     }) {
    return (
        !!message &&
            <div className={style.info_message} id="info-message-body">
                {
                    (message.showCloseButton === undefined || message.showCloseButton) &&
                        <div className={style.info_message_close}
                             onClick={() => setMessage(null)}>
                            <i className="fas fa-times"/>
                        </div>
                }
                <div className={style.info_message_icon}>
                    <i className="fas fa-exclamation-triangle"/>
                </div>
                <div className={style.info_message_text} id="info-message-text">
                    <p className={style.info_message_title}>{message?.title}</p>
                    <p style={{margin: 0}}>{message?.text}</p>
                </div>
            </div>
    );
}

export default InfoMessage