import axios from 'axios';
import { routes } from '../utils/routes';

const sendChatCategory = (chatCategory,freetext, config) => {
	return axios.post(`${config.xApiUrl}${routes.AGENT_FEEDBACK}`, chatCategory, {
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => response);
};
const sendTransferNote = (config,data) => {
	return axios.post(`${config.xApiUrl}${routes.AGENT_NOTE}`, data, {
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => response);
};

const getContactTranscript = (config) => {
	const url = `${config.xApiUrl}${routes.CONTACTS_TRANSCRIPT}/${config.contactId}` +
		`?source=raw` +
		`&clientApiKey=${config?.clientApiKey || ''}` +
		`&getPrevious=${config?.getPrevious || 0}`;
	return axios.get(url,{
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => {
		return response?.data?.data;
	});
};

const getContactData = (params, config) => {
	const {cmd, apiKey, sessionId} = params;
	const url = `${config.xApiUrl}${routes.CONTACT_DATA}` +
		`?cmd=${cmd}` +
		`&apiKey=${apiKey}` +
		`&sessionId=${sessionId}`;
	return axios.get(url,{
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => {
		return response?.data;
	});
};

const sendTemplateUsageStatistic = (params, config) => {
	const {contactId, agentUsername, templateId, isModified} = params;
	if (!config?.instanceId || !contactId || !templateId) {
		return new Promise(resolve => resolve(null));
	}
	const postData = {
		contactId: contactId,
		agentUsername: agentUsername,
		instanceId: config.instanceId,
		templateId: templateId,
		isModified: isModified
	};
	return axios.post(`${config.xApiUrl}${routes.TEMPLATE_USAGE_STATISTIC}`, postData, {
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => response);
};

const getRoutingProfiles = (instanceId, config) => {
	const url = `${config.xApiUrl}/agent/auth` +
		`?provider=test` +
		`&test-key=${config.testApiKey}` +
		`&cmd=get-routing-profiles` +
		`&instance_id=${instanceId}`
		;
	return axios.get(url,{
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => {
		return response?.data?.result;
	});
};

const setRoutingProfile = (instanceId, agentId, routingProfileId, config) => {
	const url = `${config.xApiUrl}/agent/auth` +
		`?provider=test` +
		`&test-key=${config.testApiKey}` +
		`&cmd=change-routing-profile` +
		`&instance_id=${instanceId}` +
		`&agent-id=${agentId}` +
		`&routing-profile-id=${routingProfileId}`
	;
	return axios.get(url,{
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => {
		return response?.data?.result;
	});
};

export default {
	sendChatCategory,
	getContactTranscript,
	getContactData,
	sendTemplateUsageStatistic,
	sendTransferNote,
	getRoutingProfiles,
	setRoutingProfile
};