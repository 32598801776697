import React, {useState} from "react";
import ExTimer from "../common/Timer/ExTimer";

import styles from "./Contacts.module.css";
import {
    AlertErrors
} from "../../utils/constant";
import InfoMessage from "../common/InfoMessage/InfoMessage";
import {transformToMessageTime} from "../../utils/transformers";
import {ThreeDots as LoaderThreeDots} from "react-loader-spinner";
import ContactPreviewLabel from "./ContactPreviewLabel"
import useExceptionStore from "../../stores/Exception";
import {useShallow} from "zustand/react/shallow";

const Contacts = ({
                      contacts,
                      currentContact,
                      unreadMessages,
                      setCurrentContact,
                      messages,
                      agentName,
                      acceptContact,
                      rejectContact,
                      incoming,
                      incomingContact,
                      showAlert,
                      contactsErrorStatus,
                      onEndChat,
                  }) => {
    const { infoMessage, setInfoMessage } = useExceptionStore(useShallow(state => ({...state})))
    const [contactProcessing, setContactProcessing] = useState(false);


    const getChatStateLabel = (contact) => {
        if (!contact) {
            return '';
        }
        try {
            const contactId = contact.connection.contactId;
            switch (contact.state?.type) {
                case 'ended':
                    return ' Chat ended';
                case 'error':
                    if (contactsErrorStatus[contactId]) {
                        switch (contactsErrorStatus[contactId]) {
                            case 'missed':
                                return ' Chat missed'
                            case 'rejected':
                                return ' Chat rejected'
                        }
                    }
                    return ' Chat missed';
            }
        } catch (e) {
            console.error(e.message);
        }
        return '';
    }

    const getCustomerName = (contact) => {
        let customerName = contact?.attributes?.customerName;
        if (!customerName) {
            showAlert(AlertErrors.COMMON);
            return '(unknown)';
        }
        return getTrimmedCustomerName(customerName, 22);
    }

    const getChatContactClassName = (keyName) => {
        let result = 'row chat-contact';
        result += (keyName === currentContact ? ' active' : '');
        result += (currentContact !== keyName && unreadMessages[keyName] ? ' unread' : '');
        result += (contacts[keyName]?.state?.type === 'error' ?
                contactsErrorStatus && contactsErrorStatus[keyName] ?
                    ' ' + contactsErrorStatus[keyName]
                    :
                    ' missed'
                :
                ''
        );
        return result;
    }

    const onRemoveButtonClick = (e, contactId) => {
        e.stopPropagation();
        onEndChat(contactId);
    }

    return <div className="col-md-4 chat-contacts">
        <InfoMessage message={infoMessage} setMessage={setInfoMessage} />
        {
            Object.keys(contacts).map((keyName, idx) => (
                contacts[keyName] ?
                    <div className={getChatContactClassName(keyName)}
                         key={idx}
                         onClick={() => {
                             if (contacts[keyName]?.state?.type === 'error') {
                                 setCurrentContact(null);
                             } else {
                                 setCurrentContact(keyName);
                             }
                         }}
                    >
                        {
                            !!infoMessage && idx < 1 && <div className={styles.hr_line}/>
                        }
                        <div className="col-md-8">
                            <h3 className="chat-contact-name">
                                {getCustomerName(contacts[keyName])}
                            </h3>
                        </div>
                        <div className="col-md-4">
                            {
                                unreadMessages[keyName]?.length ?
                                    <div className={styles.new_messages_counter} id="new-messages-counter">
                                        {unreadMessages[keyName].length}
                                    </div>
                                    :
                                    ''
                            }
                            <div className={'remove-button ' + (contacts[keyName]?.state?.type === 'error' ? '' : 'd-none')}>
                                <button type="button" className="btn" onClick={e => onRemoveButtonClick(e, keyName)}>
                                    Remove
                                </button>
                            </div>
                        </div>
                        <div className="chat-contact-preview col-md-12">
                            <div className={"chat-label " + (getChatStateLabel(contacts[keyName]).length ? '' : 'd-none')}>
                                {getChatStateLabel(contacts[keyName])}
                                <ChatEndTime contact={contacts[keyName]} />
                            </div>
                            {
                                messages[keyName] &&
                                messages[keyName][messages[keyName].length - 1] &&
                                contacts[keyName]?.state?.type !== 'ended' ?
                                    <Timers contact={contacts[keyName]} messages={messages[keyName]} />
                                    :
                                    <ChatEndTotalTime contact={contacts[keyName]} messages={messages[keyName]} />
                            }
                        </div>
                    </div>
                    :
                    ''
            ))
        }
        {
            agentName && !incoming && contacts && !Object.keys(contacts).length &&
            <div className="no-chats">No chats to display</div>
        }
        <div className={"row chat-contact-incoming " + (incoming ? '' : 'd-none')}>
            {
                !!infoMessage && <div className={styles.hr_line}/>
            }
            <div className={styles.contact_overlay + (contactProcessing ? "" : " d-none")}>
                <LoaderThreeDots
                    color="#d7d7d7"
                    height={50}
                    width={50}
                    timeout={0}
                />
            </div>
            <div className="contacts-head col-md-6">
                <div className="">
                    <span
                        className="chat-contact-name"> {incoming && incomingContact && getTrimmedCustomerName(incomingContact.customerName, 22)}</span>
                </div>
                <ContactPreviewLabel incomingContact={incomingContact}/>
            </div>
            <div className="contact-buttons">
                <button type="button" className="btn btn-small btn-info btn-accept"
                        onClick={acceptContact}>
                    <i className="fas fa-check"/> <p>Accept</p></button>
                <button type="button" className="btn btn-small btn-info btn-reject"
                        onClick={() => rejectContact(setContactProcessing)}>
                    <i className="fas fa-times"/> <p>Reject</p></button>
            </div>
        </div>
    </div>
};

const Timers = ({contact, messages}) => {

    const searchLastMessageAbsoluteTime = () => {
        let msgIdx = false;
        let joinedIdx = false;
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].Type === 'MESSAGE') {
                if (msgIdx === false && messages[i].ParticipantRole !== 'SYSTEM') {
                    try {
                        const jsonMsg = JSON.parse(messages[i].Content);
                        if (jsonMsg.type !== 'EVENT') {
                            msgIdx = i;
                        }
                    } catch (e) {
                        msgIdx = i;
                    }
                }
            } else if (messages[i].Type === 'EVENT') {
                if (messages[i].ParticipantRole === 'AGENT'
                    && messages[i].ContentType === 'application/vnd.amazonaws.connect.event.participant.joined'
                ) {
                    joinedIdx = i;
                    if (msgIdx !== false && msgIdx > joinedIdx) {
                        return messages[msgIdx]?.AbsoluteTime;
                    } else {
                        return messages[joinedIdx]?.AbsoluteTime;
                    }
                }
                if (messages[i].ContentType === 'application/vnd.amazonaws.connect.event.transfer.succeeded') {
                    break;
                }
            }
        }
        return 0;
    }

    const totalTime = contact?.state?.timestamp;
    const lastMessage = searchLastMessageAbsoluteTime();
    const totalTimeDiff = Date.now() - (new Date(totalTime).getTime() || Date.now());
    const lastMessageDiff = Date.now() - (new Date(lastMessage).getTime() || Date.now());

    return <div>
        <i className="fas fa-clock"/>
        <span className={styles.timer} id="start-chat-time-timer">
            <ExTimer initialTime={totalTimeDiff} />
        </span>
        <i className="fas fa-hourglass-end"/>
        <span key={lastMessageDiff} className={styles.timer} id="last-message-timer">
            <ExTimer initialTime={lastMessageDiff} startImmediately={lastMessage !== 0} />
        </span>
    </div>
};

const ChatEndTime = ({contact}) => {
    let endTime = Date.now();
    try {
        endTime = contact?.state?.timestamp?.getTime();
    } catch (e) {}

    return (
        !contact?.connected &&
        <div className={styles.chat_end_time}>
            {transformToMessageTime(endTime)}
        </div>
    );
};

const ChatEndTotalTime = ({contact, messages}) => {
    let totalTime = 0;
    try {
        // calculate agent chat time
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].Type === 'EVENT'
                && messages[i].ParticipantRole === 'AGENT'
                && messages[i].ContentType === 'application/vnd.amazonaws.connect.event.participant.joined'
            ) {
                totalTime = contact?.state?.timestamp?.getTime() - new Date(messages[i].AbsoluteTime).getTime();
                break;
            }
        }
    } catch (e) {}

    return (
        contact?.state?.type === 'ended' ?
            <div className={styles.chat_end_total_time}>
                <span className={styles.timer} id="end-chat-time-timer">
                    <i className="fas fa-clock"/>
                    <ExTimer initialTime={totalTime} startImmediately={false} />
                </span>
                <div>Total chat time</div>
            </div>
            :
            ''
    );
};

const getTrimmedCustomerName = (name, length = 25) => {
    let tName = '';
    if (name && name.value) {
        tName = name.value.substring(0, length);
        if (name.value.length > length) {
            tName += '...';
        }
    }
    return tName;
}

export default Contacts;
export {getTrimmedCustomerName};
