import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const useExceptionStore = create(
    devtools(
        immer((set, get) => ({
            alertError: null,
            infoMessage: null,
            setAlertError: (alert) =>
                set((state) => {
                    state.alertError = alert;
                }),
            setInfoMessage: (infoMessage) =>
                set((state) => {
                    state.infoMessage = infoMessage;
                }),
        })),
    ),
);

export default useExceptionStore;
