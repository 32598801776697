import React, {useEffect, useMemo, useRef, useState} from 'react';
import {components} from "react-select";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import CloseIcon from '../common/CloseIcon/CloseIcon';
import api from '../../services/api';
import {CHAT_CATEGORY_INPUT} from '../../utils/constant';

import style from './DropDownChatCategories.module.css';
import {transformToSelect} from '../../utils/transformers';
import {sendErrorLog} from "../../utils/logger";
import {Oval as LoaderOval} from "react-loader-spinner";
import {loadSelectedCategory, saveSelectedCategory} from "../../utils/storage";
import {ModalFooter} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";

function DropDownChatCategories({
    chatCategories,
    selectedChatCategory,
    setSelectedChatCategory,
    setRenderDropDownCategories,
    contacts,
    currentContact,
    agentConfiguration,
    disconnectChat,
    config,
}) {

    const [showInput, setShowInput] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [requestError, setRequestError] = useState(null);
    const [freeTextInput, setFreeTextInput] = useState("");
    const [showInputError, setShowInputError] = useState(false);
    const [showInputRequired, setShowInputRequired] = useState(false);
    const [showSelectError, setShowSelectError] = useState(false);
    const [answersCount, setAnswersCount] = useState(2);
    const textAreaRef = useRef(null);
    const initialContactOnlineState = useRef(null);

    const isContactConnected = () => {
        return contacts[currentContact] && contacts[currentContact]?.state?.type === 'connected';
    }

    const useAutosizeTextArea = (
        textAreaRef,
        value
    ) => {
        useEffect(() => {
            if (textAreaRef) {
                // We need to reset the height momentarily to get the correct scrollHeight for the textarea
                textAreaRef.style.height = "0px";
                const scrollHeight = textAreaRef.scrollHeight;

                // We then set the height directly, outside of the render loop
                // Trying to set this with state or a ref will product an incorrect value.
                textAreaRef.style.height = scrollHeight + "px";
            }
        }, [textAreaRef, value]);
    };
    useAutosizeTextArea(textAreaRef.current, freeTextInput);

    useEffect(() => {
        setRequestError(null);
        initialContactOnlineState.current = isContactConnected();
    }, []);

    const options = useMemo(() => {
        let result = [];
        try {
            const queueName = contacts[currentContact]?.connection?.getQueue().name;
            if (chatCategories['categories'] && Array.isArray(chatCategories['categories'])) {
                const queueNameUpper = queueName.toUpperCase();
                chatCategories.categories.forEach((category, index) => {
                    if (category.queues.map(q => q.toUpperCase()).indexOf(queueNameUpper) !== -1) {
                        result = [...result, ...transformToSelect(category.items)];
                        if (category.answersCount) {
                            setAnswersCount(category.answersCount)
                        }
                    }
                });
            } else if (chatCategories['queueCategories'] && Array.isArray(chatCategories['queueCategories'])) {
                result = [...result, ...transformToSelect(chatCategories.queueCategories[queueName])];
                if (chatCategories.queueCategories['all']) {
                    result = [...result, ...transformToSelect(chatCategories.queueCategories['all'])]
                    if (chatCategories.queueCategories['all'].answersCount) {
                        setAnswersCount(chatCategories.queueCategories['all'].answersCount)
                    }
                }
            }
        } catch (e) {}

        return result;
    }, [chatCategories]);

    const updateSelectedChatCategory = (data) => {
        setSelectedChatCategory(prevState => ({
            ...prevState,
            [currentContact]: data
        }));
    };

    const onSelectQuestion = (value) => {
        if (value.filter(v => v.value === CHAT_CATEGORY_INPUT).length) {
            setShowInput(true);
            if (!freeTextInput.length) {
                setShowInputRequired(true)
            } else {
                setShowInputRequired(false)
            }
        } else {
            setShowInputRequired(false)
        }
        if (!value.length) {
            setShowInputRequired(false)
        }
        setShowInput(false);
        updateSelectedChatCategory({
            value: value,
            saved: false
        });
    };

    const onInput = ({target}) => {
        setFreeTextInput(target.value);
        if (target.value.length > 300) {
            setShowInputError(true)
        } else {
            setShowInputError(false)
        }
        if (!target.value.length && selectedCategories.filter((item) => item.value === CHAT_CATEGORY_INPUT).length) {
            setShowInputRequired(true)
        } else {
            setShowInputRequired(false)
        }
    };

    const onClose = () => {
        updateSelectedChatCategory({
            value: [],
            saved: false
        });
        setRenderDropDownCategories(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const sCC = selectedChatCategory[currentContact];
        const isValidParams = !sCC
            || (sCC && !sCC?.value?.length)
            || sCC?.value?.length === 1 && sCC?.value?.[0]?.value === CHAT_CATEGORY_INPUT && !freeTextInput

        if (isValidParams) {
            setShowSelectError(true)
            return
        } else {
            setShowSelectError(false)
        }
        setRequestError(null);
        setIsSaving(true);
        let selectedCategory = []
        if (sCC?.value) {
            selectedCategory = sCC.value.map(function (item) {
                if (item.value === CHAT_CATEGORY_INPUT) {
                    return {answer: item.label, uuid: item.id}
                }
                return {answer: item.value, uuid: item.id}
            })
        }
        const chatCategory = {
            contactId: currentContact,
            agentUsername: agentConfiguration?.username,
            selectedChatCategories: selectedCategory,
            freeTextInput: freeTextInput,
        };
        api.sendChatCategory(chatCategory, freeTextInput, config)
            .then(() => {
                let selectedCategories = loadSelectedCategory() || {}
                selectedCategories[currentContact] = {
                    answers: selectedCategory,
                    freeTextInput: freeTextInput,
                    saved: true
                }
                saveSelectedCategory(selectedCategories)
                if (isContactConnected() === initialContactOnlineState.current) {
                    disconnectChat();
                }
                setRenderDropDownCategories(false);
            })
            .catch(error => {
                setRequestError('Can\'t save selected category. Check your network connection.');
                sendErrorLog('Send chat category failed', {error: error});
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    const selectedCategories = (selectedChatCategory[currentContact]?.value || []);

    return (
        <div className={"categories-modal"}>
            <div className={"agent-overlay " + (isSaving ? "" : "d-none")}>
                <LoaderOval
                    color="#d7d7d7"
                    height={50}
                    width={50}
                    timeout={0}
                />
            </div>
            <Modal.Header className={style.modalHeader}>
                <div className={style.modalTitle + " category-modal modal-title-custom"}>
                    Select a Category
                </div>
                <button
                    className={style.iconBtn}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <p className={style.title + " category-modal title"}>What best describes the topic of this chat?</p>
                <Form
                    onSubmit={onSubmit}
                    className={style.form + " categories_form"}>
                    &nbsp;
                    <div className={selectedCategories.length && "form-group-custom"}>
                        {selectedCategories.length ? <label>Category</label> : ""}
                        <Select
                            // menuIsOpen={true}
                            isMulti={true}
                            options={selectedCategories.length < answersCount ? options : []}
                            displayValue={"name"}
                            value={selectedCategories}
                            onChange={onSelectQuestion}
                            placeholder={'Category'}
                            classNamePrefix={'react-select'}
                            className={"category-inputs"}
                            components={{
                                // ValueContainer: LimitedChipsContainer,
                                DropdownIndicator,
                                MultiValueRemove: MultiValueRemove,
                                ClearIndicator: ClearIndicator,
                            }}
                            hideSelectedOptions={false}
                            styles={{
                                container: (base, state) => ({
                                    ...base,
                                    fontWeight: 'bold',
                                    color: showSelectError ? "red" : '#061D53',
                                }),
                                placeholder: (base, state) => ({
                                    ...base,
                                    fontSize: 14,
                                    color: '#4c4c4c'
                                }),
                                indicatorSeparator: (base, state) => ({
                                    ...base,
                                    backgroundColor: 'none'
                                }),
                                control: (base, state) => ({
                                    ...base,
                                    '&:hover': {borderColor: 'gray'}, // border style on hover
                                    border: '1px solid', // default border color,
                                    borderColor: "#061D53",
                                    boxShadow: 'none', // no box-shadow,
                                    width: "540px !important"
                                }),
                                multiValue: (base, state) => ({
                                    ...base,
                                    backgroundColor: '#fff',
                                    borderRadius: '100px',
                                    border: '1px solid #061D53',
                                    color: '#061D53',
                                }),
                            }}
                        />
                    </div>
                    <>
                        <p className={style.inputLabel + " category-modal title"}>Please describe your chat
                            topic in as
                            few
                            words as
                            possible</p>
                        <TextareaAutosize
                            className={"form-control category-inputs free-text-input" + (showInputError || showInputRequired ? " error" : "")}
                            type="text"
                            placeholder="Type Topic Here..."
                            onKeyUp={onInput}
                            required={showInput}
                            as={"textarea"}
                            rows={5}
                            ref={textAreaRef}
                        />
                        <div
                            className={"free-text-hint" + (showInputError || showInputRequired ? " error" : "")}>
                             <span>
                            {showInputError ? "Maximum character count exceeded" : " *Required"}
                                 &nbsp;

                        </span>
                            <span>{freeTextInput.length ? freeTextInput.length : 0}/300</span>
                        </div>


                    </>


                </Form>
                {
                    requestError && <div className={style.error}>{requestError}</div>
                }
            </Modal.Body>
            <ModalFooter>
                <section className={style.container + " categories_buttons"}>
                    <div className={style.containerBtn + " categories_buttons_container"}>
                        <Button
                            variant="outline-primary"
                            className={style.btnOutline + " category-cancel"}
                            onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!selectedCategories.length || (!freeTextInput.length && selectedCategories.filter((item) => item.value === CHAT_CATEGORY_INPUT).length) || showInputError}
                            className={style.btn + " category-submit"}
                            id="chat-category-save-button"
                            onClick={onSubmit}
                            type="submit">
                            Save
                        </Button>
                    </div>
                </section>
            </ModalFooter>
        </div>
    );
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            {
                props.selectProps.menuIsOpen ?
                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.42501 5C0.975012 5 0.662679 4.79567 0.488012 4.387C0.312679 3.979 0.383345 3.61667 0.700012 3.3L3.30001 0.700002C3.40001 0.600002 3.50835 0.525002 3.62501 0.475002C3.74168 0.425002 3.86668 0.400002 4.00001 0.400002C4.13335 0.400002 4.25835 0.425002 4.37501 0.475002C4.49168 0.525002 4.60001 0.600002 4.70001 0.700002L7.30001 3.3C7.61668 3.61667 7.68735 3.979 7.51201 4.387C7.33735 4.79567 7.02501 5 6.57501 5H1.42501Z"
                            fill="#061D53"/>
                    </svg>
                    :
                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.30001 4.3L0.700012 1.7C0.383345 1.38333 0.312679 1.021 0.488012 0.613C0.662679 0.204333 0.975012 0 1.42501 0H6.57501C7.02501 0 7.33735 0.204333 7.51201 0.613C7.68735 1.021 7.61668 1.38333 7.30001 1.7L4.70001 4.3C4.60001 4.4 4.49168 4.475 4.37501 4.525C4.25835 4.575 4.13335 4.6 4.00001 4.6C3.86668 4.6 3.74168 4.575 3.62501 4.525C3.50835 4.475 3.40001 4.4 3.30001 4.3Z"
                            fill="#061D53"/>
                    </svg>
            }
        </components.DropdownIndicator>
    );
};

const Option = (props, selectedChatCategory) => {
    return (
        <components.Option {...props}
                           className={style.optionStyle + (selectedChatCategory?.value?.length && selectedChatCategory?.value?.filter(v => v.label === props.data.label).length ? " selected" : "")}>
            {props.data.label}
        </components.Option>
    )
}

const LimitedChipsContainer = ({children, hasValue, ...props}) => {
    if (!hasValue) {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }

    const CHIPS_LIMIT = 1;
    const [chips, otherChildren] = children;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;
    const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);
    return (
        <components.ValueContainer {...props}>
            {displayChips}
            <div>{overflowCounter > 0 && `+ ${overflowCounter}`}</div>
        </components.ValueContainer>
    );
};

const MultiValueRemove = ({children, ...props}, selectedCategories) => {
    return (
        <components.MultiValueRemove {...props}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.00003 5.9333L1.73337 9.19997C1.61114 9.32219 1.45559 9.38331 1.2667 9.38331C1.07781 9.38331 0.922255 9.32219 0.800032 9.19997C0.67781 9.07775 0.616699 8.92219 0.616699 8.7333C0.616699 8.54442 0.67781 8.38886 0.800032 8.26664L4.0667 4.99997L0.800032 1.7333C0.67781 1.61108 0.616699 1.45553 0.616699 1.26664C0.616699 1.07775 0.67781 0.922193 0.800032 0.799971C0.922255 0.677749 1.07781 0.616638 1.2667 0.616638C1.45559 0.616638 1.61114 0.677749 1.73337 0.799971L5.00003 4.06664L8.2667 0.799971C8.38892 0.677749 8.54448 0.616638 8.73337 0.616638C8.92226 0.616638 9.07781 0.677749 9.20003 0.799971C9.32225 0.922193 9.38337 1.07775 9.38337 1.26664C9.38337 1.45553 9.32225 1.61108 9.20003 1.7333L5.93337 4.99997L9.20003 8.26664C9.32225 8.38886 9.38337 8.54442 9.38337 8.7333C9.38337 8.92219 9.32225 9.07775 9.20003 9.19997C9.07781 9.32219 8.92226 9.38331 8.73337 9.38331C8.54448 9.38331 8.38892 9.32219 8.2667 9.19997L5.00003 5.9333Z"
                    fill="#061D53"/>
            </svg>
        </components.MultiValueRemove>
    );
};

const ClearIndicator = (props) => {
    const {
        children = <>clear</>,
        getStyles,
        innerProps: {ref, ...restInnerProps},
    } = props;

    const handleClearValue = () => {
        ref.blur();
    };

    return (
        <></>
        // <div
        //     {...restInnerProps}
        //     ref={ref}
        //     style={getStyles("clearIndicator", props)}
        //     onClick={handleClearValue}
        // >
        //     <div style={{padding: "0px 5px"}}>
        //         <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        //             <path
        //                 d="M6.99999 8.39999L2.09999 13.3C1.91665 13.4833 1.68332 13.575 1.39999 13.575C1.11665 13.575 0.883321 13.4833 0.699987 13.3C0.516654 13.1167 0.424988 12.8833 0.424988 12.6C0.424988 12.3167 0.516654 12.0833 0.699987 11.9L5.59999 6.99999L0.699987 2.09999C0.516654 1.91665 0.424988 1.68332 0.424988 1.39999C0.424988 1.11665 0.516654 0.883321 0.699987 0.699987C0.883321 0.516654 1.11665 0.424988 1.39999 0.424988C1.68332 0.424988 1.91665 0.516654 2.09999 0.699987L6.99999 5.59999L11.9 0.699987C12.0833 0.516654 12.3167 0.424988 12.6 0.424988C12.8833 0.424988 13.1167 0.516654 13.3 0.699987C13.4833 0.883321 13.575 1.11665 13.575 1.39999C13.575 1.68332 13.4833 1.91665 13.3 2.09999L8.39999 6.99999L13.3 11.9C13.4833 12.0833 13.575 12.3167 13.575 12.6C13.575 12.8833 13.4833 13.1167 13.3 13.3C13.1167 13.4833 12.8833 13.575 12.6 13.575C12.3167 13.575 12.0833 13.4833 11.9 13.3L6.99999 8.39999Z"
        //                 fill="#061D53"/>
        //         </svg>
        //     </div>
        // </div>
    );
};

export default DropDownChatCategories;
