import React, {useEffect, useMemo, useState} from "react";
import {ThreeDots as LoaderThreeDots} from "react-loader-spinner";

export const CustomerAttributesMessage = (props) => {
    const {getContactDataAttributes, messageTime} = props;

    const [contactMetadata, setContactMetadata] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hideInfo, setHideInfo] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getContactDataAttributes()
            .then(data => {
                setContactMetadata(data || {});
                /* // Disabled
                if (!data?.productName
                    && !data?.attributes?.patientName
                    && !data?.attributes?.mrn
                ) {
                    setHideInfo(true);
                } else {
                    setHideInfo(false);
                }
                */
            })
            .finally(() => setIsLoading(false));
    }, []);

    return <>
        {
            <div className={"row message-left " + (hideInfo ? 'd-none' : '')}>
                <div className="col-md-9">
                    <p>
                        Customer info:<br/>
                        {
                            isLoading ?
                                <LoaderThreeDots
                                    color="#d7d7d7"
                                    height={30}
                                    width={30}
                                    timeout={0}
                                />
                                :
                                <>
                                    Product Name: {contactMetadata?.productName || '-'}<br/>
                                    Patient Name: {contactMetadata?.attributes?.patientName || '-'}<br/>
                                    Medical Record Number: {contactMetadata?.attributes?.mrn || '-'}<br/>
                                    Email Address: {contactMetadata?.attributes?.email || '-'}<br/>
                                    Phone Number: {contactMetadata?.attributes?.phoneNumber || '-'}
                                </>
                        }
                    </p>
                    <span className={"message-time "}>
                        {messageTime}
                    </span>
                </div>
                <div className="col-md-3">
                </div>
            </div>
        }
    </>;
}
