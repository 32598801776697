import React, {useEffect, useMemo, useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import CloseIcon from '../../common/CloseIcon/CloseIcon';
import api from '../../../services/api';

import style from './ChangeRoutingProfile.module.css';
import {Oval as LoaderOval} from "react-loader-spinner";
import {ModalFooter} from "react-bootstrap";

function ChangeRoutingProfile({
    setShowModal,
    testApiKey
}) {
    const [inProgress, setInProgress] = useState(false);
    const [requestError, setRequestError] = useState(null);
    const [routingProfilesList, setRoutingProfilesList] = useState([]);
    const [routingProfile, setRoutingProfile] = useState(null);

    const instanceId = '93f32403-d05f-432c-b46c-501295ecf3f6';

    useEffect(() => {
        setRequestError(null);
        updateRoutingProfilesList();
    }, []);

    const updateRoutingProfilesList = () => {
        const config = {
            xApiUrl: process.env.REACT_APP_AMAZON_API_URL,
            xApiKey: process.env.REACT_APP_AMAZON_API_KEY,
            testApiKey: testApiKey
        };
        setInProgress(true);
        api.getRoutingProfiles(instanceId, config)
            .then(data => {
                setRoutingProfilesList(data || []);
            })
            .finally(() => {
                setInProgress(false);
            });
    }

    const updateRoutingProfile = (routingProfileId) => {
        const config = {
            xApiUrl: process.env.REACT_APP_AMAZON_API_URL,
            xApiKey: process.env.REACT_APP_AMAZON_API_KEY,
            testApiKey: testApiKey
        };
        setInProgress(true);
        console.log(window.myCPP?.agent?.getConfiguration())
        const agentId = window.myCPP?.agent?.getConfiguration().agentARN.split('/')[3];
        api.setRoutingProfile(instanceId, agentId, routingProfileId, config)
            .then(() => {
                setShowModal(false);
                window.location.reload();
            })
            .finally(() => {
                setInProgress(false);
            });
    }

    const onClose = () => {
        setShowModal(false);
    };

    return (
        <div className={"categories-modal"}>
            <div className={"agent-overlay " + (inProgress ? "" : "d-none")}>
                <LoaderOval
                    color="#d7d7d7"
                    height={50}
                    width={50}
                    timeout={0}
                />
            </div>
            <Modal.Header className={style.modalHeader}>
                <div className={style.modalTitle + " category-modal modal-title-custom"}>
                    Change Agent Routing Profile
                </div>
                <button
                    className={style.iconBtn}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <br />
                <p className={style.title + " category-modal title"}>Routing profiles:</p>
                <Select
                    className={style.select}
                    options={routingProfilesList.map(routingProfile => ({value: routingProfile.id, label: routingProfile.name}))}
                    onChange={(e) => setRoutingProfile(e.value)}
                />
                {
                    requestError && <div className={style.error}>{requestError}</div>
                }
            </Modal.Body>
            <ModalFooter>
                <section className={style.container + " categories_buttons"}>
                    <div className={style.containerBtn + " categories_buttons_container"}>
                        <Button
                            variant="outline-primary"
                            className={style.btnOutline + " category-cancel"}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={style.btn}
                            id="chat-category-save-button"
                            onClick={() => {
                                updateRoutingProfile(routingProfile);
                            }}
                        >
                            Update
                        </Button>
                    </div>
                </section>
            </ModalFooter>
        </div>
    );
}

export default ChangeRoutingProfile;
