import { useEffect, useState } from 'react';

export const useChatCategories = (chatCategories, contacts, currentContact) => {
	const [renderComponent, setRenderComponent] = useState(false);

	useEffect(() => {
		if (!chatCategories?.enable
			|| ['error', 'missed'].indexOf(contacts[currentContact]?.state?.type) !== -1
		) {
			setRenderComponent(false);
			return;
		}
		let result = false;
		let queueName = null;
		try {
			queueName = contacts[currentContact]?.connection?.getQueue().name.toUpperCase();
		} catch (e) {}
		if(chatCategories['categories']){
			chatCategories?.categories?.forEach((category) => {
				if (category.queues.map(q => q.toUpperCase()).indexOf(queueName) !== -1) {
					result |= true;
				}
			});
		} else {
			result = chatCategories.queueCategories[queueName];
			if (chatCategories.queueCategories['all']) {
				result = (result) ? [...result, ...chatCategories.queueCategories['all']] : chatCategories.queueCategories['all'];
			}
		}
		setRenderComponent(result);
	}, [chatCategories, currentContact]);

	return renderComponent;
};